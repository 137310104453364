import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import ProgressBar from "@ramonak/react-progress-bar";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Topbar } from "../../components/Topbar/Topbar";
import "./StudentsListPage.scss";
import Button from "../../components/Button/Button";

const StudentsListPage = () => {
  const iconSize = 15;
  const iconColour = "#323232";
  const style = {
    padding: "10px",
    backgroundColor: "white",
    boxShadow: "0 1px 10px rgb(0 0 0 / 0.1)",
    borderRadius: "20px",
    marginLeft: "5px",
  };

  return (
    <div className="page-wrapper" id="StudentsListPage">
      <Sidebar />
      <div className="internal-page-wrapper">
        <Topbar title="My Classes" />
        <main>
          <div>
            <div className="table-top-bar">
              <h2>Students List</h2>
              <div className="buttons-wrapper">
                <Button text="Add Students" />
                <Button linkTo="/create-assignment" text="Create Assignment" />
              </div>
            </div>

            <table>
              <colgroup>
                <col span={1} style={{ width: "5%" }} />
                <col span={1} style={{ width: "19%" }} />
                <col span={1} style={{ width: "19%" }} />
                <col span={1} style={{ width: "19%" }} />
                <col span={1} style={{ width: "19%" }} />
                <col span={1} style={{ width: "19%" }} />
              </colgroup>

              <div className="table-titles-wrapper">
                <tr>
                  <th></th>
                  <th>USERNAME</th>
                  <th>NAME</th>
                  <th>AVERGAE GRADE</th>
                  <th>LATEST GRADE</th>
                  <th>ACTIONS</th>
                </tr>
              </div>

              <tbody>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => {
                  return (
                    <tr>
                      <td>
                        {" "}
                        <input type="checkbox" name="" id="" />{" "}
                      </td>
                      <td>@johndoe</td>
                      <td>John Doe</td>
                      <td>
                        <p>89</p>
                        <ProgressBar
                          completed={89}
                          bgColor="#316AFF"
                          height="6px"
                          width="40%"
                          isLabelVisible={false}
                        />
                      </td>
                      <td>75%</td>
                      <td>
                        <AiFillEdit
                          size={iconSize}
                          color={iconColour}
                          style={style}
                        />{" "}
                        <AiFillDelete
                          size={iconSize}
                          color={iconColour}
                          style={style}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  );
};

export default StudentsListPage;
