import { useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";

function PrivacyPolicy() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      <div id="PrivacyPolicy">
        <div className="site-container" style={{
            marginTop: '50px'
        }}>
            <h1>Privacy Policy</h1>

            <p>This website and app is operated by MASTERQ LTD</p>
<p>We take your privacy very seriously and we ask that you read this privacy policy carefully as it contains important information on:</p>
<ul>
<li>the personal information we collect about you</li>
<li>what we do with your information, and</li>
<li>who your information might be shared with.</li>
</ul>
<p>We may change this privacy policy from time to time. You should check this policy occasionally to ensure you are aware of the most recent version that will apply each time you access our website and app.</p>
<p><strong>Who are we?</strong></p>
<p>MASTERQ LTD (&lsquo;we&rsquo; or &lsquo;us&rsquo;) are a &lsquo;data controller&rsquo; for the purposes of the Data Protection Act 2018, the UK General Data Protection Regulation and any subsequent UK data protection legislation and we are responsible for, and control the processing of, your personal information.</p>
<p><strong>Who does this privacy policy apply to?</strong></p>
<p>This privacy policy applies to any individuals whose personal information we process during the course of business, which includes:</p>
<ul>
<li>Our customers meaning someone who uses our services for or on behalf of a school, LA or similar organisation (&lsquo;School&rsquo;) to administer a quiz and retrieve results &ndash; e.g. a school&rsquo;s staff ;</li>
<li>Our prospective customers and recipients of our marketing communications;</li>
<li>Visitors to our website;</li>
<li>Our suppliers, service providers, agents, and subcontractors.</li>
</ul>
<p>Whilst we also process personal data of students who engage with our website, app and services, we do this as a processor on behalf of that student&rsquo;s School. This means that the School is the controller and as such this policy does not apply to students who use our app and services.&nbsp;<strong>Please see &lsquo;How do we use Students&rsquo; Personal Data&rsquo; below</strong>.</p>
<p>If you are unsure how this policy applies to you, please contact us (see &lsquo;How to contact us&rsquo; below) and we will be happy to help.</p>
<p><strong>How do we use students&rsquo; personal data?</strong></p>
<p>We process personal data of students who engage with our app and services. We do this as a processor on behalf of that student&rsquo;s School. This means that the School is the controller and we only process students&rsquo; personal data in accordance with the School&rsquo;s instructions.</p>
<p>As such, should a student (or their parent on their behalf) wish to enforce their rights in respect of how we process their data, this should be directed to their School who will instruct us accordingly. Still, we understand that it is important for you understand how and why we process students&rsquo; personal data. We have a &lsquo;<a href="#">Student Privacy FAQs</a>&rsquo; which provides you with further information about how we process students&rsquo; personal data and why.</p>
<p><strong>What information do we collect and process?</strong></p>
<p><strong>Personal information provided by you</strong></p>
<p>During the course of accessing our website, app, using our products and services, contacting us, providing services to us, or otherwise dealing with us, you may provide us with a range of personal data which we will use in the course of our business. This personal information may include:</p>
<ul>
<li>Your name or another unique identifier;</li>
<li>Your address;</li>
<li>Your age and/or date of birth;</li>
<li>Your contact details (which may include your landline telephone number, mobile number, and/or email address);</li>
<li>Payment or other financial details (e.g. payment card information, bank information);</li>
<li>Professional information (e.g. job title and/or type, subject specialism, professional qualifications, industry); and</li>
<li>Other related information.</li>
</ul>
<p><strong>Information we collect automatically</strong></p>
<p>Our website, app and services may collect certain information about you automatically (such as your IP address, MAC address and IMEI number, browser type and version, operating system, geo-location data based on your IP address).</p>
<p><strong>Personal information provided by third parties</strong></p>
<p>Occasionally we may receive information about you from other sources (such as our suppliers, customers, or group companies), which we will add to the information we already hold about you in order to help us operate our business effectively.</p>
<p><strong>Personal information about other individuals</strong></p>
<p>If you give us information on behalf of someone else, you confirm that the other person has appointed you to act on his/her behalf and has agreed that you can:</p>
<ul>
<li>transfer the personal data and give consent on his/her behalf to the processing of his/her personal data;</li>
<li>receive on his/her behalf any data protection notices;</li>
<li>give consent to the transfer of his/her personal data abroad; and</li>
<li>give consent to the processing of his/her sensitive personal data (if applicable).</li>
</ul>
<p><strong>How we use your data and our legal basis for processing your data</strong></p>
<p>We may process your data for a variety of reasons, including because:</p>
<ul>
<li>we are legally obliged to e.g. to confirm your identity;</li>
<li>the processing is necessary for the performance of the contract with you to provide our Services; or</li>
<li>it is in our legitimate business interests to do so.</li>
</ul>
<p>In some instances, we will rely on your consent to process personal data and where we do this, it will be flagged to you at the time.</p>
<p>Our main processing activities for personal data, and the legal basis on which we perform those activities are:</p>
<p><strong>Prospective customers/marketing recipients:</strong></p>
<p>We will process your personal data in order to contact you in relation to our products and services and keep a record of our communications (e.g. sales telephone calls, quotations and offers).</p>
<p>Our legal basis for doing so is our legitimate interests, which allows us to market our products and services to individuals provided there is a business case for doing so and our interests do not override the rights of the individuals in question. If you wish to object to direct marketing, you may do so by contacting us.</p>
<p><strong>Customer/users:</strong></p>
<p>We will process your personal data in order to provide our products/services to you and to provide you with information and updates regarding the same. Our legal basis for doing so is that the processing is necessary for the performance of a contract. We will also keep a record of your data and use it for related purposes, including account management, customer support, and audit purposes, on the basis that we have a legitimate interest in doing so.</p>
<p>We may also contact you in relation to our other products and services. Our legal basis for doing so will be the same as for prospective customers, as set out above.</p>
<p><strong>Suppliers, service providers etc.:</strong></p>
<p>We will process your personal data in order to receive goods and/or services from you and to manage our relationship, including making payments to you, dealing with accounts issues, placing orders etc. Our legal basis for doing so is that the processing is necessary for the performance of a contract.</p>
<p><strong>Other processing activities</strong></p>
<p><strong>Monitoring and recording communications</strong></p>
<p>We may monitor and record communications with you (such as telephone conversations and emails) for the purpose of preventing unauthorised access and modifications to our services, training, fraud prevention, and/or quality assurance. We may also retain copies of communications and details provided to us by you, for example support requests, account queries, complaints, for internal account management and auditing purposes. This is done on the basis of legitimate interests and to comply with our legal and regulatory obligations.</p>
<p><strong>Storage of your information and who your information might be shared with</strong></p>
<p>We store your personal data on servers in data centres in&nbsp;<strong>the UK</strong>, provided by Amazon Web Services (AWS). AWS datacentres are compliant with the international information security standard, ISO 27001.</p>
<p>For more information about AWS&rsquo;s ISO 27001 certification, please visit this webpage:<br /><a href="https://aws.amazon.com/compliance/iso-27001-faqs/">https://aws.amazon.com/compliance/iso-27001-faqs/</a></p>
<p>We may disclose your personal data to:</p>
<ul>
<li>other companies within our group to the extent that there is a legitimate interest in doing so to support our business aims;</li>
<li>law enforcement agencies in connection with any investigation to help prevent unlawful activity; and</li>
<li>a third party purchaser if we sell our business, in which case, customer and user information will be a transferred asset.</li>
</ul>
<p>We also may disclose your personal data our agents and service providers, to the extent that they require access to the data in order to provide goods/services to us, in which case they will be bound by a contract requiring them to process personal data in accordance with the requirements prescribed by data protection law. Some of these agents and service providers may be based outside of the UK (or require the transfer of your personal data outside of the UK). In such cases, we only work with suppliers that transfer to territories which the UK has determined provides an adequate level of protection or otherwise ensure appropriate safeguards are in place to protect your personal data. In particular:</p>
<ul>
<li>we share limited data with our customer support software, Intercom, including teachers&rsquo; names, school names and teacher email addresses. This allows us to help teachers with any technical problems quickly and easily, via email, or by an online chat system. Intercom is based in the USA. Following the ICO guidance that the EU-US Privacy Shield is no longer valid, we have signed a standard customer data processing addendum with Intercom so that Standard Contractual Clauses (SCC) are incorporated &ndash;&nbsp;<a href="https://www.intercom.com/legal/privacy">https://www.intercom.com/legal/privacy</a></li>
<li>we use Twilio for the password recovery system (via text messages) to allow users to reset their passwords. Twilio is ISO 27001 registered and we have Standard Contractual Clause (SCC) in place as part of our agreement with Twilio &ndash;&nbsp;<a href="https://www.twilio.com/legal/data-protection-addendum">https://www.twilio.com/legal/data-protection-addendum</a></li>
<li>we use Xero for our accountancy system which stores School addresses and the name and email of our School contacts, e.g. finance officer. We have entered into a data processing addendum with Xero &ndash;&nbsp;<a href="https://www.xero.com/uk/about/legal/terms/data-processing-terms/">https://www.xero.com/uk/about/legal/terms/data-processing-terms/</a></li>
<li>we use Google Firebase (which is based in the USA) for the purposes of usage analytics, crash reporting, provisioning push notifications to devices. We have signed a standard customer data processing addendum with Intercom so that Standard Contractual Clauses (SCC) are incorporated &ndash;&nbsp;<a href="https://firebase.google.com/support/privacy">https://firebase.google.com/support/privacy</a></li>
<li>we use the Dropbox file sharing system which may transfer personal data outside of the UK &ndash;&nbsp;<a href="https://aem.dropbox.com/cms/content/dam/dropbox/www/en-us/business/solutions/solutions/dfb_security_whitepaper.pdf">https://aem.dropbox.com/cms/content/dam/dropbox/www/en-us/business/solutions/solutions/dfb_security_whitepaper.pdf</a></li>
<li>We also use Campus (provided by Sprint Education) for our email marketing to you which may transfer personal data outside of the UK. We have entered into their processing agreement &ndash;&nbsp;<a href="https://sprint-education.co.uk/legal/data-processing-agreement">https://sprint-education.co.uk/legal/data-processing-agreement</a></li>
</ul>
<p><strong>Keeping your data secure</strong></p>
<p>We will use technical and organisational measures to safeguard your personal data, for example:</p>
<ul>
<li>Access to your account is controlled by a password and username that are unique to you</li>
<li>We encrypt your data both when it&rsquo;s stored (at rest) and when transferring it (in transit)</li>
{/* <li>All MasterQ employees and contractors with access to the servers or who may visit schools are DBS checked</li> */}
<li>All MasterQ staff use password protected computers that are locked when not in use</li>
<li>The MasterQ office is locked at all times, and protected by an alarm</li>
<li>Staff are trained regularly on security</li>
<li>All MasterQ employee accounts are password protected</li>
<li>When teachers and school staff create accounts, we verify their position with the school before giving them access to MasterQ</li>
<li>Our data backups are deleted after 7 days and until then are stored on secure AWS servers in the UK</li>
<li>The database is only accessible by MasterQ employees who need access</li>
</ul>
<p>While we will use all reasonable efforts to safeguard your personal data, you acknowledge that the use of the internet is not entirely secure and for this reason we cannot guarantee the security or integrity of any personal data that are transferred from you or to you via the internet. If you have any particular concerns about your information, please contact us (see &lsquo;How to contact us&rsquo; below).</p>
<p><strong>What can I do to keep my information safe?</strong></p>
<p>If you want detailed information from Get Safe Online on how to protect your information and your computers and devices against fraud, identity theft, viruses and many other online problems, please visit www.getsafeonline.org. Get Safe Online is supported by HM Government and leading businesses.</p>
<p><strong>Transfers of your information out of the UK</strong></p>
<p>We may transfer your personal data outside of the UK. We will only do this if appropriate safeguards are place in accordance with data protection legislation. For example, where relevant, we have entered into Standard Contractual Clauses (SCC) that will safeguard your privacy rights and give you remedies in the unlikely event of a security breach.</p>
<p><strong>How long do we keep your personal information?</strong></p>
<p>We will keep user data as long as it is evident to us the user may still need it to use MasterQ. MasterQ uses a 2-step process for deleting user data: deactivation and then anonymisation.</p>
<p><strong>Deactivation&nbsp;</strong>is flagging a user as inactive in the database.&nbsp;When this happens, the user can no longer log in and use the website, and staff at the user&rsquo;s school can no longer see the user or their activity. The data is still associated with the user&rsquo;s name and username. It is possible to reverse a deactivation, for example when teachers want to reactivate a student account.</p>
<p>An individual&rsquo;s data is deactivated in these situations, whichever comes first:</p>
<ul>
<li>When a staff member at the user&rsquo;s school deletes the user&rsquo;s account</li>
<li>When a staff member at the user&rsquo;s school asks us to delete the user&rsquo;s account</li>
<li>When all of a school&rsquo;s trials and subscriptions with MasterQ have been expired for 180 days, we will delete all the users within the school</li>
<li>Other related situations</li>
</ul>
<p><strong>Anonymising&nbsp;</strong>a user&rsquo;s data involves removing any identifying information. We keep information such as the user&rsquo;s activity in MasterQ, whether they were a student or a teacher, and their school, but we permanently delete their name, username, password, email address, mobile number, and UPN. This is not reversible. We retain the anonymised data in order to learn about and improve our service.</p>
<p>We will anonymise a user&rsquo;s data in these situations, whichever comes first:</p>
<ul>
<li>When the user has been deleted for 90 days</li>
<li>When a staff member at the user&rsquo;s school asks us to anonymise the user</li>
<li>Other related situations</li>
</ul>
<p>Schools often provide MasterQ with spreadsheets containing students&rsquo; information in order for us to set up their accounts. We delete these spreadsheets after 6 months.</p>
<p>On request, we can anonymise an individual&rsquo;s or school&rsquo;s data in 48 hours.</p>
<p><strong>What rights do you have?</strong></p>
<p>You are responsible for ensuring that information you provide to us is accurate, complete and up-to-date. You can review and change your information by contacting us.</p>
<p>You have a number of rights in relation to your personal data, these include the right to:</p>
<ul>
<li>find out how we process your data;</li>
<li>request that your personal data is corrected if you believe it is incorrect or inaccurate;</li>
<li>obtain restriction on our, or object to, processing of your personal data;</li>
<li>if we are relying on consent, you can withdraw your consent to our processing of your personal data (including any direct marketing);</li>
<li>if we are relying on legitimate interests for direct marketing, you can object to receiving such direct marketing;</li>
<li>obtain a copy of the personal data we process concerning you. We will take steps to verify your identity before responding to your request. Once we have verified your identity we will respond as soon as possible and in any event within one month.</li>
<li>lodge a complaint with the UK supervisory body, the Information Commissioner&rsquo;s Office (the ICO) here&nbsp;<a href="https://ico.org.uk/">https://ico.org.uk/</a>. If you have a concern or complaint about the way we handle your data, we ask that you contact us in the first instance to allow us to investigate and resolve the matter as appropriate.</li>
</ul>
<p>If you would like to exercise any of your rights or find out more, please visit our Data, Privacy and GDPR Help Page for details of how you can manage your data through your account or contact us.</p>
<p><strong>Personal Data Breach Policy</strong></p>
<p>We collect the minimum personal information necessary to offer our service, and we have taken every reasonable precaution to prevent personal data breaches and to regularly monitor for signs of security events. However, if a personal data breach does occur, our policy is to take the following steps:</p>
<ol>
<li>Escalate</li>
</ol>
<p>At the first indication that there may have been a personal data breach, the incident will be immediately escalated to the MasterQ management team.</p>
<ol start={2}>
<li>Assess</li>
</ol>
<p>The MasterQ management team will assess the consequences of the data breach and take action to contain it if needed.</p>
<ol start={3}>
<li>Notify the affected party</li>
</ol>
<p>The MasterQ management team will notify the affected party of the data breach within 48 hours. For schools, this is the relevant staff member. For individual subscribers, this is the person who purchased the subscription.</p>
<ol start={4}>
<li>Mitigate</li>
</ol>
<p>MasterQ will then work with the affected school or person who purchased the subscription to mitigate any consequences of the personal data breach.</p>
<ol start={5}>
<li>Document</li>
</ol>
<p>MasterQ will internally document all data breaches, including the causes, consequences, and actions taken.</p>
<ol start={6}>
<li>Report to the ICO</li>
</ol>
<p>If the data breach was likely to result in a high risk to a user&rsquo;s rights and freedoms, MasterQ will report it to the Information Commissioner&rsquo;s Office within 72 hours of discovering it.</p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
