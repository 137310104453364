import React from 'react';

const DateTimeDisplay = (props: { value: number, type: string, isDanger: boolean }) => {
  return (
    <div className={props.isDanger ? 'countdown danger' : 'countdown'}>
      <p>{props.value}</p>
      <span>{props.type}</span>
    </div>
  );
};

export default DateTimeDisplay;
