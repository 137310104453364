import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from './useCountdown';
import './CountdownTimer.scss';

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Expired!!!</span>
      <p>Please select a future date and time.</p>
    </div>
  );
};

const ShowCounter = (props: { days: number, hours: number, minutes: number, seconds: number }) => {
  return (
    <div id="CountdownTimer">
        <div className="show-counter">
        <div
            className="countdown-wrapper"
        >
            {/* <DateTimeDisplay value={props.days} type={'Days'} isDanger={props.days <= 3} /> */}
            {/* <p>:</p> */}
            <DateTimeDisplay value={props.hours} type={'Hours'} isDanger={false} />
            <p>:</p>
            <DateTimeDisplay value={props.minutes} type={'Mins'} isDanger={false} />
            <p>:</p>
            <DateTimeDisplay value={props.seconds} type={'Seconds'} isDanger={false} />
        </div>
        </div>
    </div>
  );
};

const CountdownTimer = (props: { submit: () => void, targetDateInMilliseconds: number }) => {
  const [days, hours, minutes, seconds] = useCountdown(props.targetDateInMilliseconds);

  if (days + hours + minutes + seconds <= 0) {
    props.submit()
    return<></>;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
