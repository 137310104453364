import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

function FilterQuestionsModal(props: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const handleClose = () => props.setShowModal(false);

  return (
    <>
      <Modal show={props.showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">

            <Form.Label>Select exam board(s)</Form.Label>

              <div style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr'

              }}>
                <Form.Check
                  name="all"
                  type="checkbox"
                  id="all"
                  label="All"
                  defaultChecked
                />

                <Form.Check
                  name="wjec"
                  type="checkbox"
                  id="wjec"
                  label="WJEC"
                />
                  
                <Form.Check
                    name="edexcel"
                    type="checkbox"
                    id="edexcel"
                    label="EDEXCEL"
                  />

                <Form.Check
                  name="aqa"
                  type="checkbox"
                  id="aqa"
                  label="AQA"
                />
              </div>
              
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" form="my-form" type="submit">
            Save exam boards
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FilterQuestionsModal;
