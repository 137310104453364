import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Login.scss";
import { useUser } from "../../context/UserContext";
import { useNavigate } from "react-router";

export const Login = (): JSX.Element => {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [passwordError, setPasswordError] = useState("");
  // const [emailError, setemailError] = useState("");
  const { login, validateCookie } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    validateCookie()
      .then((res: boolean) => {
        if (res) {
          // NOTE: use `window.location.replace` here instead of the using `navigate()` and
          // instead of using `window.location.href` for the following reason:
          // When a user opens the login page, if a valid jwt cookie exists, it auto sends them
          // to the /dashboard page but if they click on the back button, the `.replace()` methods
          // ensures the user is taken back to the homepage and not the login page, because it works
          // like a HTTP redirect. The reason this is useful is that `navigate()` and `window.location.href`
          // will make the back button take the user back from the dashboard to the login page, but the
          // login page will redirect the user back to the dashboard.
          window.location.replace("/dashboard");
        }
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const loginSuccessful = await login(username, password);

    if (loginSuccessful) {
      navigate("/dashboard");
    } else {
      setPasswordError("Invalid username and/or password");
    }
  };

  return (
    <div className="page-wrapper" id="loginPage">
      <Row>
        <Col md={3} className="loginColumn">
          <div className="loginColumnWrapper">
            <h1>MasterQ</h1>
            <p>Ace your exams</p>
            <form id="loginform" onSubmit={loginSubmit}>
              <div className="form-group">
                <label>Username</label>
                <input
                  type="text"
                  className="form-control"
                  id="EmailInput"
                  name="EmailInput"
                  aria-describedby="emailHelp"
                  placeholder="Enter username"
                  onChange={(event) => setUsername(event.target.value)}
                />
                {/* <small id="emailHelp" className="text-danger form-text">
                  {emailError}
                </small> */}
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  onChange={(event) => setPassword(event.target.value)}
                />
                <small id="passworderror" className="text-danger form-text">
                  {passwordError}
                </small>
              </div>
              <a id="forgotPasswordLink" href="/forgot-password">
                Forgot password?
              </a>
              <button type="submit" className="btn btn-primary">
                Login
              </button>
            </form>
          </div>
        </Col>
        <Col md={9} className="rightColumn"></Col>
      </Row>
    </div>
  );
};
