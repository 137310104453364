import { Sidebar as SB, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "./Sidebar.scss";
import whiteLogo from "../../assets/logoWhite.svg";
import calendar from "../../assets/calendar.svg";
import settings from "../../assets/settings.svg";
import logoutIcon from "../../assets/logout.svg";
import dashboard from "../../assets/dashboard.svg";
import coursesIcon from "../../assets/courses-icon.svg";
import { Link } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import { useEffect } from 'react';

const Sidebar = (): JSX.Element => {
  const { enrolments, fetchEnrolments, logout, user } = useUser();

  useEffect(() => {
    console.log('called useEffect')
    const fetchEnrolmentsIfNotAlreadyLoaded = async () => {
      await fetchEnrolments();
    }
    if (enrolments.length === 0) {
      console.log('reloading enrolments')
      fetchEnrolmentsIfNotAlreadyLoaded()
    }
  }, [])

  const currentDate = new Date().toLocaleDateString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  return (
    <div id="sidebarWrapper">
      <SB /*width="200px" commented because width is made dynamic in css*/ width="180px" backgroundColor="#050E22">
        <div id="logo">
          <img src={whiteLogo} alt="" />
        </div>
        <Menu>
          {/* NOTE: use `component={<Link to="/dashboard" />}` instead of href="" because the former navigates without reloading the page,
              but the latter reloads the whole page to navigate. */}
          <MenuItem
            active={window.location.pathname === '/dashboard'}
            icon={<img src={dashboard} alt="Dashboard"></img>}
            component={<Link to="/dashboard" />}
          >
            Dashboard
          </MenuItem>
          <SubMenu
            icon={<img src={coursesIcon} alt="Courses"></img>}
            label="Courses"
            defaultOpen
          >
            {enrolments.map((e) => {
              const path = `/subjects/${e.subjectId}`;
              return (
                <MenuItem
                  active={window.location.pathname === path}
                  component={
                    <Link
                      to={path}
                    />
                  }
                >
                  {e.className}
                </MenuItem>
              );
            })}
          </SubMenu>
          {/* <MenuItem icon={<img src={settings} alt="Settings"></img>}>
            Settings
          </MenuItem> */}
        </Menu>
        <div id="bottom">
          <Menu>
            <MenuItem
              id="log-out"
              style={{ bottom: "0px" }}
              icon={<img src={logoutIcon} alt="Log out"></img>}
              onClick={logout}
            >
              Log out
            </MenuItem>
          </Menu>
          <br />

          <div id="calendar">
            <img src={calendar} alt="" />
            <br />
            {currentDate}
          </div>
        </div>
      </SB>
    </div>
  );
};

export default Sidebar;
