import ProgressBar from "@ramonak/react-progress-bar";
// import ProgressBarCircular from 'react-customizable-progressbar';
import Sidebar from "../../components/Sidebar/Sidebar";
import { Topbar } from "../../components/Topbar/Topbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./ModulesListPage.scss";
import Button from "../../components/Button/Button";
import { useState, useEffect, useRef } from "react";
import { ExamBoard, Module } from "../../types";
import axiosClient from "../../axiosClient";
import TestExamModal from "../../components/Modal/TestExamModal";
import { Loader } from "rsuite";
// import MultiSelectDropDown from "../../components/MultiSelectDropDown/MultiSelectDropDown";
// import { MdFilterAlt } from "react-icons/md";
import FilterQuestionsModal from "../../components/Modal/FilterQuestionsModal";
// import { Form } from "react-bootstrap";
import BootstrapButton from 'react-bootstrap/Button';
import ProgressBarCicularCut from "../../components/ProgressBarCircularCut/ProgressBarCircularCut";

const ModulesListPage = (props: any) => {
  const [subjectName, setSubjectName] = useState<string>("")
  const [subjectTotalQuestions, setSubjectTotalQuestions] = useState<number>(0)
  const [examBoardsSelected, setExamBoardsSelected] = useState<string[]>([])
  const [examBoardFiltersApplied, setExamBoardFiltersApplied] = useState<boolean>(false);
  const [modules, setModules] = useState<Module[]>([]);
  const [examBoards, setExamBoards] = useState<ExamBoard[]>([]);
  const [isLoadingModules, setIsLoadingModules] = useState<boolean>(true);
  const [isLoadingExamBoards, setIsLoadingExamBoards] = useState<boolean>(true);
  const [showTestExamModal, setShowTestExamModal] = useState<boolean>(false);
  const [showFilterQuestionsModal, setShowFilterQuestionsModal] = useState<boolean>(false);

  const handleShowTestExamModal = () => setShowTestExamModal(true);
  // const handleShowFilterQuestionsModal = () => setShowFilterQuestionsModal(true);

  const navigate = useNavigate();
  let { subjectId } = useParams();

  const getModules = async (examBoards: string[] = []) => {
    setIsLoadingModules(true);

    try {
      let endpoint = `/subjects/${subjectId}/modules`;
      if (examBoards.length) {
        endpoint += `?examBoards=${examBoards.join(',')}`
      }

      const resp: {
        subjectId: number,
        subjectName: string,
        subjectTotalQuestions: number,
        modules: Module[]
      } = (
        await axiosClient.get(endpoint)
      ).data;

      setSubjectName(resp.subjectName);
      setSubjectTotalQuestions(resp.subjectTotalQuestions);
      setModules(resp.modules);
      setIsLoadingModules(false);

      if (examBoards.length) {
        setExamBoardFiltersApplied(true);
      }
    } catch (err) {
      alert("Error occured whilst loading modules. Please seek support!");
      console.error(err);
    }

  };

  const clearExamBoardsSelection = () => {
    const allExamBoardsCheckbox = document.getElementById("All") as HTMLInputElement;
    if (allExamBoardsCheckbox) {
      allExamBoardsCheckbox.checked = true;
    }

    setExamBoardsSelected([]);
    setExamBoardFiltersApplied(false);
    // Notice the checkboxes selector below retrieves all checkboxes, except the 'All' one.
    const checkboxes = document.querySelectorAll('.checkbox-wrapper input[type="checkbox"]:not(#All)')
    for (let i = 0; i < checkboxes.length; i++) {
      const cb = checkboxes[i] as HTMLInputElement;
      cb.checked = false;
    }
  }

  useEffect(() => {
    if (!subjectId) {
      navigate("/dashboard");
    }

    const getExamBoards = async () => {
      setIsLoadingExamBoards(true)
      try {
        const resp: ExamBoard[] = (
          await axiosClient.get(`/subjects/${subjectId}/exam-boards`)
        ).data;
        setExamBoards(resp)
        setIsLoadingExamBoards(false)
      }

      catch (err) {
        alert("Error occured whilst loading exam boards. Please seek support!");
        console.error(err);
      }
    }

    getModules();
    getExamBoards();
    // clear exam board selections on page load - this is needed when navigating from one subject to another, because
    // without this hook the exam boards remain selected.
    clearExamBoardsSelection();

    // Ignore the ESLINT error message for the [] dependency below.
    // If I do as it suggests, it calls /subjects/id/modules infinitely
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectId]);

  let modulesSection;
  if (modules.length === 0) {
    modulesSection = <p>No modules found.</p>;
  } else {
    modulesSection = (
      <tbody>
        {modules.map((m) => {

          if (m.numberOfQuestions === 0) {
            return undefined;
          }

          return (
            <tr>
              <td>{m.name}</td>
              <td>{m.numberOfQuestions}</td>
              <td>
                <p>{m.numberOfQuestionsCompleted} / {m.numberOfQuestions}</p>
                <ProgressBar
                  completed={m.numberOfQuestionsCompleted / m.numberOfQuestions * 100}
                  bgColor="#316AFF"
                  height="6px"
                  width="40%"
                  isLabelVisible={false}
                />
              </td>
              <td>
                <Link to="/practice" state={{
                  assignmentName: 'Practice mode: ' + m.name,
                  practiceModuleId: m.id,
                  practiceSubjectId: subjectId,
                  examBoards: examBoardFiltersApplied ? examBoardsSelected : []
                }}>
                  <BootstrapButton
                    variant="outline-dark"
                    type="button"
                    style={{
                      float: 'right'
                    }}
                  >Practice
                  </BootstrapButton>
                </Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }

  let mainSection;
  if (isLoadingModules) {
    mainSection = (
      <div
        style={{
          display: "grid",
          height: "100%",
        }}
      >
        <Loader
          size="md"
          content="Loading..."
          style={{
            margin: "auto",
          }}
        />
      </div>
    )
  } else {
    mainSection = (
      <main>
        <TestExamModal setShowModal={setShowTestExamModal} showModal={showTestExamModal} subjectId={Number(subjectId)}></TestExamModal>
        <FilterQuestionsModal setShowModal={setShowFilterQuestionsModal} showModal={showFilterQuestionsModal}></FilterQuestionsModal>
        <div>
          <div className="table-top-bar">
            <h2>Modules List</h2>
            <div style={{
              display: 'flex'
            }}>
              <div style={{
                margin: 'auto',
              }}>
                <BootstrapButton
                  variant="primary"
                  onClick={handleShowTestExamModal}
                >
                  Test Exam
                </BootstrapButton>
              </div>

            </div>

          </div>

          <table>
            <colgroup>
              <col span={1} style={{ width: "35%" }} />
              <col span={1} style={{ width: "25%" }} />
              <col span={1} style={{ width: "25%" }} />
              <col span={1} style={{ width: "15%" }} />
            </colgroup>

            <div className="table-titles-wrapper">
              <tr>
                <th>NAME</th>
                <th>QUESTIONS</th>
                <th>DONE</th>
                <th>{/* ACTIONS */}</th>
              </tr>
            </div>
            {modulesSection}
          </table>
        </div>
      </main>
    )
  }

  let filtersSidebar;
  if (isLoadingExamBoards) {
    filtersSidebar = (
      <div
        style={{
          display: "grid",
          height: "100%",
        }}
      >
        <Loader
          size="md"
          content="Loading..."
          style={{
            margin: "auto",
          }}
        />
      </div>
    )
  } else {
    filtersSidebar = (
      <div className="container-fluid">
        <div className="table-top-bar">
          <h4>Exam boards</h4>
        </div>

        <form style={{
          marginBottom: '60px'
        }}
          onSubmit={async (e) => {
            e.preventDefault();
            // const checkedBoxes = document.querySelectorAll('.checkbox-wrapper input[type="checkbox"]:checked')
            // console.log(checkedBoxes)

            await getModules(examBoardsSelected);
          }}
        >
          <div className="checkbox-wrapper">
            <input type="checkbox" name="All" id="All" defaultChecked
              disabled={examBoardFiltersApplied}
              onClick={(e) => {
                if ((e.target as HTMLInputElement).checked) {
                  clearExamBoardsSelection();
                }
              }} />
            <label htmlFor="All">All ({subjectTotalQuestions})</label>
          </div>

          {examBoards.map(examBoard => {
            return (
              <div className="checkbox-wrapper">
                <input type="checkbox" name={examBoard.name} id={String(examBoard.id)}
                  disabled={examBoardFiltersApplied}
                  onClick={(e) => {
                    (document.getElementById("All") as HTMLInputElement).checked = false;
                    const idOfCheckboxClickedOn = (e.target as HTMLInputElement).id;

                    if ((e.target as HTMLInputElement).checked) {
                      // appends the exam board to the `examBoardsSelected` state
                      setExamBoardsSelected([...examBoardsSelected, idOfCheckboxClickedOn])
                    }

                    else {
                      // removes the exam board from the `examBoardsSelected` state
                      setExamBoardsSelected(examBoardsSelected.filter(
                        examBoard => examBoard !== idOfCheckboxClickedOn
                      ))
                    }
                  }} />
                <label htmlFor={String(examBoard.id)}>{examBoard.name} ({examBoard._count.questions})</label>
              </div>
            )
          })}

          <BootstrapButton
            variant={examBoardFiltersApplied ? "dark" : "outline-dark"}
            type="submit"
            disabled={examBoardFiltersApplied}
            style={{
              width: '90%',
              marginTop: '10px'
            }}
          >
            {examBoardFiltersApplied ? (
              'Filters Applied'
            ) : `Apply ${examBoardsSelected.length !== 0 ? `(${examBoardsSelected.length})` : ''} filters`}

          </BootstrapButton>

          {examBoardFiltersApplied && (
            <BootstrapButton
              variant="outline-danger"
              size="sm"
              style={{
                marginTop: '10px',
                width: '90%',
              }}
              onClick={async () => {
                clearExamBoardsSelection()
                await getModules([])
              }}
            >
              Clear filters
            </BootstrapButton>
          )}
        </form>

        {/* <h4>Progress</h4>
          <div>
            <div>
              <ProgressBarCicularCut progress={50} steps={1064} type="fraction" color="#316aff" text="questions done"></ProgressBarCicularCut>
              <a href="#"> View more</a>
            </div>
            <div style={{
              position: 'relative',
              top: '-20px'
            }}>
              <ProgressBarCicularCut progress={73} steps={120} type="percentage" color="#20BB27" text="average score"></ProgressBarCicularCut>
              <a href="#"> View more</a>
            </div>
          </div>   */}

      </div>
    )
  }

  return (
    <div className="page-wrapper" id="ModulesListPage">
      <Sidebar />
      <div className="internal-page-wrapper">
        <Topbar title={subjectName + " Question Bank"} />

        <div className="row" style={{
          height: isLoadingModules ? '100%' : 'unset'
        }}>

          <div className="col-lg-9 col-xl-10">
            {mainSection}
          </div>

          <div className="col-lg-3 col-xl-2" id="modules-page-filter-sidebar">
            {filtersSidebar}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModulesListPage;
