import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { AiOutlineArrowRight } from "react-icons/ai";
import "react-circular-progressbar/dist/styles.css";
import "./AssignmentCard.scss";
import { Link } from "react-router-dom";
import { Submission } from "../../types";

const AssignmentCard = ({
  submission,
  isUpcoming,
}: {
  submission: Submission;
  isUpcoming: boolean;
}) => {
  const percent = Math.round(submission.score);
  const iconToShow = isUpcoming ? (
    <AiOutlineArrowRight
      size={15}
      color={"black"}
      style={{
        backgroundColor: "#f7f7f7",
        padding: "4px",
        borderRadius: "15px",
        margin: "auto",
      }}
    />
  ) : (
    <CircularProgressbar
      value={percent}
      text={`${percent}%`}
      strokeWidth={10}
      styles={buildStyles({
        textSize: "28px",
        pathColor: `#316AFF`,
        textColor: "black",
      })}
    />
  );

  let dateSection;

  if (isUpcoming) {
    if (submission.dueDate) {
      dateSection = (
        <span>
          <strong>Due:</strong>{" "}{new Date(submission.dueDate).toDateString()}
        </span>
      )
    }
    else {
      dateSection = (
        <span>
          <strong>Due:</strong> No due date.
        </span>
      )
    }
  }

  else {
    if (submission.submittedDate !== null) {
      dateSection = (
        <span>
          <strong>Submitted:</strong>{" "}{new Date(submission.submittedDate).toDateString()}
        </span>
      )
    }

    else {
      dateSection = (
        <span>
          <strong>Unsubmitted. Due:</strong>{" "}{new Date(submission.dueDate).toDateString()}
        </span>
      )
    }
  }

  const innerAssignmentCardContent = (
    <>
      <div className="name-and-due-date">
        <p>{submission.assignmentName}</p>
        {dateSection}
      </div>
      <div style={{ height: "40px", display: "flex" }}>
        {iconToShow}
      </div>
    </>
  )

  if (isUpcoming) {
    return (
      <Link
        className="assignment-card"
        to="/assignment"
        state={{
          submissionId: submission.id,
          assignmentId: submission.assignmentId,
          assignmentName: submission.assignmentName
        }}
      >
        {innerAssignmentCardContent}
      </Link>
    );
  }

  else {
    return (
      <Link
        className="assignment-card"
        to={`/submissions/${submission.id}`}
      >
        {innerAssignmentCardContent}
      </Link>
    );
  }

};

export default AssignmentCard;
