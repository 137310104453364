import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useRef } from "react";
import axiosClient from "../../axiosClient";
import { useNavigate } from "react-router";
import { Assignment } from "../../types";

function TestExamModal(props: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  subjectId: number
}) {
  const handleClose = () => props.setShowModal(false);
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();

  return (
    <>
      <Modal show={props.showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Test exam details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRef} id="my-form" onSubmit={ async (e) => {
            e.preventDefault();
            const formData = Object.fromEntries(new FormData(e.target as HTMLFormElement));
            // output as an object
            console.log(formData);

            const testExam: {
              assignment: Assignment,
              submission: {
                id: number;
                submittedDate: Date | null;
                assignmentId: number;
                studentId: number;
                score: number | null;
              }
            } = (await axiosClient.post(`/assignments-practice`, {
                subjectId: props.subjectId,
                numberOfQuestions: formData.numberOfQuestions,
                examDuration: formData.examDuration
            })).data;

            handleClose();

            navigate('/assignment', {
              state: {
                submissionId: testExam.submission.id,
                assignmentId: testExam.assignment.id,
                assignmentName: testExam.assignment.name
              }
            })
          }}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Choose number of questions</Form.Label>
              <Form.Control
                type="number"
                name="numberOfQuestions"
                min={10}
                max={90}
                placeholder="Min 10, max 90"
                autoFocus
                required
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Test exam duration</Form.Label>
              <Form.Select aria-label="Test exam duration" name="examDuration">
                <option value="5">5 minutes</option>
                <option value="10">10 minutes</option>
                <option value="15">15 minutes</option>
                <option value="20">20 minutes</option>
                <option value="25">25 minutes</option>
                <option value="30">30 minutes</option>
                <option value="35">35 minutes</option>
                <option value="40">40 minutes</option>
                <option value="45">45 minutes</option>
                <option value="50">50 minutes</option>
                <option value="55">55 minutes</option>
                <option value="60">60 minutes</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" form="my-form" type="submit">
            Start practice exam
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TestExamModal;
