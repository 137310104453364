import DataTable, { TableColumn } from "react-data-table-component";
import { SimpleStudent } from "../../types";

function Table(props: {
  columns: TableColumn<any>[];
  data: SimpleStudent[] | any[];
}) {
  return (
    <DataTable
      columns={props.columns}
      data={props.data}
      pagination
      dense={false}
      striped
      customStyles={{
        headCells: {
          style: {
            fontSize: "1rem",
            color: "white",
            paddingLeft: "0 8px",
            justifyContent: "center",
            backgroundColor: "rgb(19, 34, 66)",
          },
        },
        cells: {
          style: {
            fontSize: "1rem",
          },
        },
        pagination: {
          style: {
            backgroundColor: "#f7f7f7",
            fontSize: "1rem",
            borderRadius: "0 0 10px 10px",
          },
        },
      }}
    />
  );
}

export default Table;
