import Sidebar from "../../components/Sidebar/Sidebar";
import { Topbar } from "../../components/Topbar/Topbar";
import { useLocation, useNavigate } from "react-router-dom";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import { useEffect, useRef, useState } from "react";
import "./AssignmentPage.scss";
import axiosClient from "../../axiosClient";
import { Assignment, Question } from "../../types";
import { ZoomImage } from "../../components/ZoomImage/ZoomImage";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { ImCross } from "react-icons/im";
import { AssignmentPageTitle } from "./AssignmentPageTitle";
import { AssignmentPageFooter } from "./AssignmentPageFooter";
import AssignmentPageSidebar from "../../components/AssignmentPageSidebar/AssignmentPageSidebar";

const AssignmentPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [assignment, setAssignment] = useState<Assignment>();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  // selectedAnswers maps each questionId to the answerId chosen
  const [selectedAnswers, setSelectedAnswers] = useState<{
    [key: number]: number;
  }>({});

  const navigate = useNavigate();
  const data = useLocation();

  const getSubmissionId = () => data?.state?.submissionId;
  const getAssignmentId = () => data?.state?.assignmentId;
  const getAssignmentTitle = () => data?.state?.assignmentName;

  useEffect(() => {
    console.log(questions);
  }, [questions])

  useEffect(() => {
    const getQuestions = async () => {
      try {
        // TODO: implement option submissionId or assignmentId in URL query parameters, instead of Link props values below
        // Else block - this is the case when the assignment is a current assignment which the student is yet to submit.
        // TODO: this is confusing. We GET /assignments and /submissions, but submit to /assignments.
        // Perhaps merge GET endpoints into one?
        const assignmentResp: {
          assignment: Assignment;
          questions: Question[];
        } = (await axiosClient.get("/assignments/" + getAssignmentId()))
            .data;

        setAssignment(assignmentResp.assignment);

        // this helps the timer not keep re-loading the time remaining to the assignment duration when the user answers questions/changes question,
        // because it fixes the start time so the duration calculated is always fixed too from this start time, instead of being relative to the
        // current timestamp on every question answered / question changed.
        const assignmentStartTimeKey = `master_q_assignment_${assignmentResp.assignment.id}_start_time`;
        if (!localStorage.getItem(assignmentStartTimeKey)) {
          localStorage.setItem(assignmentStartTimeKey, new Date().getTime().toString())
        }

        setQuestions(assignmentResp.questions);
        setIsLoading(false);
      } catch (err) {
        alert("Error loading assignment. Please seek support!");
        console.error(err);
      }
    };
    getQuestions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && questions.length > 0) {
      // clear all checkboxes
      document
        .querySelectorAll('input[type="checkbox"]')
        .forEach((checkbox) => {
          (checkbox as HTMLInputElement).checked = false;
        });

      document
        .querySelectorAll('.answer-option')
        .forEach((answerOption) => {
          (answerOption as HTMLDivElement).classList.remove('selected-answer-option');
        });

      let currentQuestion = questions[currentQuestionIndex];
      // if navigating to a question already answered, re-check the necessary checkbox
      if (currentQuestion.id in selectedAnswers) {
        const selectedAnswerId = selectedAnswers[currentQuestion.id];
        const selectedAnswer = document.getElementById(
          selectedAnswerId.toString(),
        );
        const checkbox = selectedAnswer?.querySelector(
          'input[type="checkbox"]',
        );

        if (checkbox) {
          selectedAnswer?.classList.add('selected-answer-option');
          (checkbox as HTMLInputElement).checked = true;
        }
      }
    }
  });

  if (isLoading) {
    return (
      <div className="page-wrapper" id="AssignmentPage">
        <Sidebar />
        <div className="internal-page-wrapper">Loading...</div>
      </div>
    );
  }

  const handleQuestionChange = (e: { selected: number }) => {
    const newQuestionIndex = e.selected;
    setCurrentQuestionIndex(newQuestionIndex);
  };

  const handleAnswerSelected = (e: any) => {
    const userClickedOnSurroundingDivToZoomOutOfImage = e.target.hasAttribute("data-rmiz-modal-content");
    // Avoid clicks on the image from changing the checked value, because clicking on the image
    // is done to enlarge it, not necessarily to select that answer.
    // ALSO avoid clicks on the div surrounding the enlarged image from changing the checked value.
    if (e.target.tagName === 'IMG' || userClickedOnSurroundingDivToZoomOutOfImage) {
      return;
    }

    // See https://stackoverflow.com/a/53815609 for why we use e.currentTarget instead of e.target
    const selectedCard = e.currentTarget as Element;

    selectedCard.parentElement
      ?.querySelectorAll('.answer-option')
      .forEach((answerOption) => {
        (answerOption as HTMLDivElement).classList.remove('selected-answer-option');
      });

    const checkboxInsideCard = selectedCard.querySelector(
      'input[type="checkbox"]',
    ) as HTMLInputElement;

    selectedCard.parentElement
      ?.querySelectorAll('input[type="checkbox"]')
      .forEach((checkbox) => {
        if (checkbox !== checkboxInsideCard) {
          (checkbox as HTMLInputElement).checked = false;
        }
      });

    // Only force the checkbox to change value when clicking on the card (not the checkbox itself),
    // because when the checkbox itself is clicked it auto changes value, which means the statement
    // below causes the click on the checkbox to cause no change at all, which is confusing.
    if (e.target.tagName !== 'INPUT') {
      checkboxInsideCard.checked = !checkboxInsideCard.checked;
    }

    if (checkboxInsideCard.checked) {
      selectedCard.classList.add('selected-answer-option')
      const updatedAnswers = {
        ...selectedAnswers,
      };
      updatedAnswers[currentQuestion.id] = Number(selectedCard.id);
      setSelectedAnswers(updatedAnswers);
    } else {
      const updatedAnswers = {
        ...selectedAnswers,
      };
      delete updatedAnswers[currentQuestion.id];
      setSelectedAnswers(updatedAnswers);
    }
  };

  const submit = async () => {
    const selectedAnswersArray = Object.keys(selectedAnswers).map(
      (questionId: string) => {
        const answerOptionId = selectedAnswers[Number(questionId)];
        return {
          questionId: Number(questionId),
          answerOptionId,
        };
      },
    );

    const payload = {
      // TODO: these two props should be read from props
      submissionId: getSubmissionId(),
      submittedAnswers: selectedAnswersArray,
    };

    try {
      const resp = await axiosClient.put(
        `/assignments/${getAssignmentId()}/submit`,
        payload,
      );

      if (resp.status === 200) {
        alert("Assignment Complete! Click 'Ok' to see your results.");
        navigate(`/submissions/${getSubmissionId()}`);
      } else {
        alert("Failed!");
      }
    } catch (err) {
      alert("Failed! Please screenshot this error and seek support.");
    }
  };

  const submitWithCheckForIncompleteQuestions = async () => {
    if (Object.keys(selectedAnswers).length < questions.length) {
      const isOkToSubmitWithoutAllAnswers = window.confirm("You haven't answered all questions. Are you sure you want to submit?")

      if (!isOkToSubmitWithoutAllAnswers) {
        return;
      }
    }

    submit()
  }

  const ABCDE = "ABCDE";
  const currentQuestion = questions[currentQuestionIndex];

  const answeredCorrectly =
    currentQuestion.answerOptions.filter(
      (answerOption) => answerOption.isCorrect && answerOption.selected,
    ).length === 1;

  const delimeters = [
    {
      // NOTE: `display` is by default `true` for the '$$' delimeter option
      // out the box from the react-latex-next package. However, I've set it
      // to `false` to ensure it doesn't render the equation in the centre
      // of the parent. Instead, `false` ensures the equation is rendered
      // in-line with the text. I should also point out that I could have just
      // used the single '$' delimeter option, where all questions with equations
      // have the equation wrapped with a single '$' either side, but I thought
      // this was risky if by accident a question has a $ sign in the question
      // text itself. In such a case, we might not realise, and the question
      // would render incorrectly. Thus, I have opted for the '$$' approach,
      // despite it meaning that we have to manually define the delimeters in
      // our implementation (which wouldn't have been the case otherwise).
      // See: https://www.npmjs.com/package/react-latex-next
      display: false,
      left: "$$",
      right: "$$",
    },
  ];

  const explanationLines = currentQuestion.explanation?.text
    ?.split("\\n")
    .map((line) => (
      <p className="explanation-line">
        <Latex delimiters={delimeters}>{line}</Latex>
      </p>
    ));

  return (
    <div className="page-wrapper" id="AssignmentPage">
      <Sidebar />
      <div className="internal-page-wrapper">
        <Topbar title={getAssignmentTitle()} />
        <main>
          <div className="inner-main-wrapper row">
            <div className="inner-max-width-wrapper col-10">
              <div className="top-area">
                <AssignmentPageTitle
                  questionId={currentQuestion.id}
                  questionNumber={currentQuestionIndex + 1}
                  totalQuestions={questions.length}
                  isAssignmentMode={true}
                  submitAssignment={submitWithCheckForIncompleteQuestions}
                />
              </div>
              <h5 className="questionTitle">
                <Latex delimiters={delimeters}>{currentQuestion.text}</Latex>
              </h5>

              {currentQuestion.imageUrl && (
                <div style={{
                  marginBottom: '50px'
                }}>
                  <ZoomImage src={currentQuestion.imageUrl} width={300}></ZoomImage>
                </div>
              )}

              {/* <div className='questionImage'></div> */}

              <div className="answer-options-wrapper">
                {currentQuestion?.answerOptions.map((answerOption, index) => {
                  const option = ABCDE[index];
                  const classes = ["answer-option"];
                  return (
                    <div
                      className={classes.join(" ")}
                      onClick={handleAnswerSelected}
                      id={answerOption.id.toString()}
                    >
                      <div>
                        <div className="top-part">
                          <label htmlFor={option}>{option}</label>
                          <input
                            type="checkbox"
                            name={option}
                            id={option}
                            style={{
                              display: 'block'
                            }}
                          />
                        </div>
                        <hr />
                        <div className="bottom-part">
                          <p>
                            <Latex delimiters={delimeters}>
                              {answerOption.text}
                            </Latex>
                          </p>
                          {answerOption.imageUrl && <ZoomImage src={answerOption.imageUrl} width={200}></ZoomImage>}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <AssignmentPageFooter
                currentQuestionIndex={currentQuestionIndex}
                handleQuestionChange={handleQuestionChange}
                totalQuestions={questions.length}
              />

              {/* <Editor initialContent={currentQuestion.explanation?.text} /> */}
            </div>
            <div className="col-2">
              <AssignmentPageSidebar
                setCurrentQuestionIndex={setCurrentQuestionIndex}
                selectedAnswers={selectedAnswers}
                questions={questions}
                assignment={assignment}
                submit={submit}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AssignmentPage;
