import { useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";

function TermsOfUse() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      <div id="TermsOfUse">
        <div className="site-container" style={{
            marginTop: '50px'
        }}>
            <h1>Terms of use</h1>
            <p><strong>Terms and Conditions of Service</strong></p>
<p><strong>BY REGISTERING AND/OR USING OUR SERVICES, YOU AGREE TO THE TERMS AND CONDITIONS BELOW. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, YOU MUST NOT USE OUR SERVICES.</strong></p>
<p><strong>MASTERQ TERMS OF USE &amp; ACCEPTABLE USE POLICY</strong></p>
<p>If you have any questions, you can contact us via email at&nbsp;<a href="mailto:support@masterq.co.uk">support@masterq.co.uk</a></p>
<p><strong>February 2023</strong></p>
<p><strong>1&nbsp;About our Terms</strong></p>
<p>1.1&nbsp;These Terms explain how you may use use of Our platform via Our website (&ldquo;<a href="http://www.masterq.co.uk/">www.masterq.co.uk</a>&ldquo;) (the &ldquo;<strong>Website</strong>&ldquo;), our MasterQ app (the &ldquo;<strong>App</strong>&ldquo;) and the services We provide to You via the Website and/or App (together, the &ldquo;<strong>Services</strong>&rdquo;).</p>
<p>1.2&nbsp;You should read these Terms carefully before using the Services.</p>
<p>1.3&nbsp;By accessing or using the Services or otherwise indicating your consent, you agree to be bound by these Terms and the documents referred to in them.</p>
<p>1.4&nbsp;If you do not agree with or accept any of these Terms, you should stop using the Services immediately.</p>
<p>1.5&nbsp;Definitions<br /><strong>Content</strong>&nbsp;&ndash; means any text, images, video, audio or other multimedia content, software or other information or material submitted to or on the Services;<br /><strong>Submission</strong>&nbsp;&ndash; means any text, images, video, audio or other multimedia content, software or other information or material submitted by you or other users to the Services;<br /><strong>Terms</strong>&nbsp;&ndash; means these terms and conditions of use as updated from time to time under clause&nbsp;<a href="https://www.masterq.co.uk/terms-of-use">16</a>;<br /><strong>Unwanted Submission</strong>&nbsp;&ndash; has the meaning given to it in clause&nbsp;<a href="https://www.masterq.co.uk/terms-of-use">8.1</a>;<br /><strong>We, us or our</strong>&nbsp;&ndash; means MasterQ Ltd, a limited company registered in England and Wales.</p>
<p><strong>You or your</strong>&nbsp;&ndash; means the person accessing or using the Services or its Content.</p>
<p><strong>2&nbsp;Using Our Services</strong></p>
<p>2.1&nbsp;The Services is for your personal and non-commercial use only.</p>
<p>2.2&nbsp;You agree that you are solely responsible for:</p>
<p>2.2.1&nbsp;all costs and expenses you may incur in relation to your use of the Services; and</p>
<p>2.2.2&nbsp;keeping your password and other account details (if applicable) confidential.</p>
<p>2.3&nbsp;The Services is intended for use by those who can access it from within the UK. If you choose to access the Services from locations outside the UK, you are responsible for compliance with local laws where they are applicable.</p>
<p>2.4&nbsp;We may prevent or suspend your access to the Services at any time for any reason whatsoever including if you do not comply with any part of these Terms, any terms or policies to which they refer or any applicable law.</p>
<p><strong>3&nbsp;App Users</strong></p>
<p>3.1&nbsp;You acknowledge that Our agreement is with You. App store providers such as Apple and Google are not a party to these Terms and have no responsibility for the App or its content.</p>
<p>3.2&nbsp;In these Terms, We refer to the site that You download the App from as the &lsquo;app store&rsquo; and We refer to their rules and policies as the &lsquo;app store rules&rsquo;. You must comply with the app store rules as well as these Terms but, if there is any conflict between them, You should follow the app store rules rather than the equivalent rule here. If You have downloaded the App through Apple&rsquo;s app store, You may be referred to as an &lsquo;iOS User&rsquo; in these Terms and related references to the &lsquo;app store&rsquo; and &lsquo;app store rules&rsquo; means those provided by Apple, including the Apple Media Services Terms and Conditions.</p>
<p>3.3&nbsp;You do not own the App or any of its contents but You may use it on devices that You own or control (and in the case of iOS Users, on Apple-branded products only), as permitted by the app store rules.</p>
<p>3.4&nbsp;If You sell or give away the device on which You have downloaded the App, You must first remove the App from the device.</p>
<p>3.5&nbsp;You are not allowed to:</p>
<p>3.5.1&nbsp;hack the App (which might include accessing its code, inserting new code, or modifying the way it functions in any way); or</p>
<p>3.5.2&nbsp;pretend that the App is Your own or make it available for others to download or use.</p>
<p><strong>4&nbsp;Restrictions on use</strong></p>
<p>4.1&nbsp;As a condition of your use of the Services, you agree:</p>
<p>4.1.1&nbsp;not to use the Services for any purpose that is unlawful under any applicable law or prohibited by these terms and conditions of use;</p>
<p>4.1.2&nbsp;not to use the Services to commit any act of fraud;</p>
<p>4.1.3&nbsp;not to use the Services to distribute viruses or malware or other similar harmful software code;</p>
<p>4.1.4&nbsp;not to use the Services for purposes of promoting unsolicited advertising or sending spam;</p>
<p>4.1.5&nbsp;not to use the Services to simulate communications from us or another service or entity in order to collect identity information, authentication credentials, or other information (&lsquo;phishing&rsquo;);</p>
<p>4.1.6&nbsp;not to use the Services in any manner that disrupts the operation of our Site or business or the website or business of any other entity;</p>
<p>4.1.7&nbsp;not to use the Services in any manner that harms minors;</p>
<p>4.1.8&nbsp;not to promote any unlawful activity;</p>
<p>4.1.9&nbsp;not to represent or suggest that we endorse any other business, product or service unless we have separately agreed to do so in writing;</p>
<p>4.1.10&nbsp;not to use the Services to gain unauthorised access to or use of computers, data, systems, accounts or networks;</p>
<p>4.1.11&nbsp;not to attempt to circumvent password or user authentication methods; and</p>
<p>4.1.12&nbsp;to comply with the provisions relating to our intellectual property rights and software contained in these terms and conditions of use.</p>
<p>4.2&nbsp;Using the software in an unlawful way (such as reproducing or redistributing it in a way that breaches these Terms and any others that apply to it) is expressly prohibited and may result in civil and criminal penalties.</p>
<p>4.3&nbsp;We are not obliged to monitor or moderate Submissions to our interactive services. Where we do monitor or moderate Submissions we shall indicate how this is performed and who should be contacted in relation to any Submission of concern to you.</p>
<p>4.4&nbsp;We may remove or edit any Submissions to any of our interactive services whether they are moderated or not.</p>
<p>4.5&nbsp;Any Submission you make must comply with our Submission standards set out below.</p>
<p><strong>5&nbsp;Submission standards</strong></p>
<p>5.1&nbsp;Any Submission or communication to users of our Services must conform to standards of accuracy, decency and lawfulness, which shall be applied in our discretion, acting reasonably. In particular, you warrant that any Submission or communication is:</p>
<p>5.1.1&nbsp;your own original work and lawfully submitted;</p>
<p>5.1.2&nbsp;factually accurate or your own genuinely held belief;</p>
<p>5.1.3&nbsp;provided with the necessary consent of any third party;</p>
<p>5.1.4&nbsp;not defamatory or likely to give rise to an allegation of defamation;</p>
<p>5.1.5&nbsp;not offensive, obscene, sexually explicit, discriminatory or deceptive; and</p>
<p>5.1.6&nbsp;unlikely to cause offence, embarrassment or annoyance to others.</p>
<p><strong>6&nbsp;Your privacy and personal information</strong></p>
<p>6.1&nbsp;Your privacy is important to us. Any personal information that you provide to us or is provided to us on your behalf will be dealt with either:</p>
<p>6.1.1&nbsp;if you are a Student, in accordance with our instructions from the Data Controller. Please see our Student Privacy FAQs <a href="#">here</a>; or</p>
<p>6.1.2&nbsp;in line with our privacy policy available at&nbsp;<a href="http://www.masterq.co.uk/privacy-policy">www.masterq.co.uk/privacy-policy</a>, which explains what personal information we collect from you, how and why we collect, store, use and share such information, your rights in relation to your personal information and how to contact us and supervisory authorities in the event you have a query or complaint about the use of your personal information.</p>
<p><strong>7&nbsp;Ownership, use and intellectual property rights</strong></p>
<p>7.1&nbsp;This Services and all intellectual property rights in it including but not limited to any Content are owned by us, our licensors or both (as applicable). Intellectual property rights means rights such as: copyright, trade marks, domain names, design rights, database rights, patents and all other intellectual property rights of any kind whether or not they are registered or unregistered (anywhere in the world). We and our licensors reserve all of our rights in any intellectual property in connection with these Terms. This means, for example, that we and they remain owners of them and free to use them as we and they see fit.</p>
<p>7.2&nbsp;Nothing in these Terms grants you any legal rights in the Services other than as necessary to enable you to access the Services. You agree not to adjust to try to circumvent or delete any notices contained on the Services (including any intellectual property notices) and in particular in any digital rights or other security technology embedded or contained within the Services.</p>
<p>7.3&nbsp;Trade marks and trade names may be used on this Services. Your use of any trade marks on the Services is strictly prohibited unless you have our prior written permission.</p>
<p><strong>8&nbsp;Submitting information to the Services</strong></p>
<p>8.1&nbsp;While we try to make sure that the Services is secure, we cannot guarantee the security of any information that you supply to us and therefore we cannot guarantee that it will be kept confidential. For that reason, you should not let us have any patentable ideas or patent applications, advertising or marketing suggestions, prototypes, or any other information that you regard as confidential, commercially sensitive or valuable (Unwanted Submissions). While we value your feedback, you agree not to submit any Unwanted Submissions.</p>
<p>8.2&nbsp;We may use any Unwanted Submissions as we see reasonably fit on a free-of-charge basis (bear in mind that we have no way of knowing whether such information is confidential, commercially sensitive or valuable because we do not monitor the Services to check for these matters). Therefore, we will not be legally responsible for keeping any Unwanted Submissions confidential nor will we be legally responsible to you or anybody else for any use of such Unwanted Submissions.</p>
<p><strong>9&nbsp;Accuracy of information and availability of the Services</strong></p>
<p>9.1&nbsp;While we try to make sure that the Services is accurate, up-to-date and free from bugs, we cannot promise that it will be. Furthermore, we cannot promise that the Services will be fit or suitable for any purpose. Any reliance that you may place on the information on this Services is at your own risk.</p>
<p>9.2&nbsp;We may suspend or terminate operation of the Services at any time as we see fit.</p>
<p>9.3&nbsp;Content is provided for your general information purposes only and to inform you about us and our products and news, features, services and other websites that may be of interest. It does not constitute technical, financial or legal advice or any other type of advice and should not be relied on for any purposes.</p>
<p>9.4&nbsp;While we try to make sure that the Services is available for your use, we do not promise that the Services is available at all times nor do we promise the uninterrupted use by you of the Services.</p>
<p><strong>10&nbsp;Hyperlinks and third party sites</strong></p>
<p>10.1&nbsp;The Website and/or App may contain hyperlinks or references to third party websites other than the Website and/or App. Any such hyperlinks or references are provided for your convenience only. We have no control over third party websites and accept no legal responsibility for any content, material or information contained in them. The display of any hyperlink and reference to any third party website does not mean that we endorse that third party&rsquo;s website, products or services. Your use of a third party site may be governed by the terms and conditions of that third party site.</p>
<p>10.2&nbsp;We reserve the right to require you to immediately remove any link to the Website at any time, and you shall immediately comply with any request by us to remove any such link.</p>
<p><strong>11&nbsp;Limitation on our liability</strong></p>
<p>11.1&nbsp;Except for any legal responsibility that we cannot exclude in law (such as for death or personal injury), we are not legally responsible for any:</p>
<p>11.1.1&nbsp;losses that:<br />(a) were not foreseeable to you and us when these Terms were formed; or<br />(b) that were not caused by any breach on our part</p>
<p>11.1.2&nbsp;any indirect or consequential loss or business losses.</p>
<p><strong>12&nbsp;Events beyond our control</strong><br />We will not be liable or responsible for any failure to perform, or delay in performance of, any of Our obligations under these Terms that is caused by any act or event beyond Our reasonable control, including failure of public or private telecommunications networks.</p>
<p><strong>13&nbsp;How to contact us for support or to report complaints, faults or abuse</strong></p>
<p>13.1&nbsp;If You require customer support or would like to report a complaint, a fault or abusive behaviour or if You have any other questions or comments in relation to the Services then You may contact Us at support@masterq.co.uk.</p>
<p>13.2&nbsp;iOS Users acknowledge that Apple has no obligation whatsoever to provide any support or maintenance services in relation to the App.</p>
<p><strong>14&nbsp;Prohibited territories and persons</strong></p>
<p>14.1&nbsp;By downloading and accessing the App, You represent and warrant to Us that You are not:</p>
<p>14.1.1&nbsp;located in a country that is subject to a US Government embargo, or that has been designated by the US Government as a &lsquo;terrorist supporting&rsquo; country; and</p>
<p>14.1.2&nbsp;on any US Government list of prohibited or restricted parties.</p>
<p>14.2&nbsp;If either of the situations in clause&nbsp;14.1&nbsp;apply to You, You are not permitted to download and use the App.</p>
<p>14.3&nbsp;We do not warrant or represent that Your use of the App is lawful in any particular jurisdiction and You are responsible for ensuring that Your access and use of the App complies with all local laws and regulations. You warrant to Us that Your activities are lawful in every jurisdiction in which You access or use the App.</p>
<p><strong>15&nbsp;Rights of third parties</strong></p>
<p>15.1&nbsp;If You are an iOS User, Apple and Apple&rsquo;s subsidiary companies are third party beneficiaries of this agreement. This means that if You breach any of these Terms, Apple has the right to enforce it and to take action against You directly, with or without Our involvement.</p>
<p>15.2&nbsp;Other than Apple and Apple&rsquo;s subsidiaries, a person who is not a party to the Terms may not enforce any of them under the Contracts (Rights of Third Parties) Act 1999.</p>
<p><strong>16&nbsp;Variation and Severance</strong></p>
<p>16.1&nbsp;We reserve the right to vary these Terms from time to time. Our updated terms will be displayed on the Services and by continuing to use and access the Services following such changes, you agree to be bound by any variation made by us. It is your responsibility to check these Terms from time to time to verify such variations.</p>
<p>16.2&nbsp;If any provision of these Terms (or part of any provision) is or becomes illegal, invalid or unenforceable, the legality, validity and enforceability of any other provision of these Terms shall not be affected.</p>
<p><strong>17&nbsp;Disputes</strong></p>
<p>17.1&nbsp;We shall apply these terms and conditions of use in our absolute discretion. In the event of your breach of these terms we may terminate or suspend your use of the Services, remove or edit Submissions, disclose Submissions to law enforcement authorities or take any action we consider necessary to remedy the breach.</p>
<p>17.2&nbsp;We will try to resolve any disputes with you quickly and efficiently. If you are unhappy with us please contact us as soon as possible.</p>
<p>17.3&nbsp;If either party wants to take court proceedings, the relevant courts of the England and Wales will have exclusive jurisdiction in relation to these Terms.</p>
<p>17.4&nbsp;Relevant law of England and Wales will apply to these Terms.</p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermsOfUse;
