import AssignmentCard from "../AssignmentCard/AssignmentCard";
import "./AssignmentSidebar.scss";
import { useEffect, useState } from "react";
import { Submission } from "../../types";
import axiosClient from "../../axiosClient";
import { useUser } from "../../context/UserContext";

const AssignmentSidebar = () => {
  const { user } = useUser();
  const studentId = user?.student?.id;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pastSubmissions, setPastSubmissions] = useState<Submission[]>([]);
  const [upcomingSubmissions, setUpcomingSubmissions] = useState<Submission[]>(
    [],
  );

  useEffect(() => {
    const getSubmissions = async () => {
      try {
        // if (user?.subscription.type === 'school') {
        const data: {
          submitted: Submission[];
          upcoming: Submission[];
        } = (await axiosClient.get(`/students/${studentId}/submissions`)).data;

        setPastSubmissions(data.submitted);
        setUpcomingSubmissions(data.upcoming);
        setIsLoading(false);
        // }

        // else if (user?.subscription.type === 'individual') {
        //   const data = (await axiosClient.get(`/students/${studentId}/submissions?onlyGetPracticeSubmissions=true`)).data;
        // }
      } catch (err) {
        alert("Error loading assignment sidebar. Please seek support!");
        console.error(err);
      }
    };
    getSubmissions();
  }, []);

  if (isLoading) {
    // TODO: use loading component here
    return <div id="assignment-sidebar">Loading...</div>;
  }

  const renderedPastSubmissions =
    pastSubmissions.length === 0 ? (
      <>None to display</>
    ) : (
      pastSubmissions.map((submission) => {
        return <AssignmentCard submission={submission} isUpcoming={false} />;
      })
    );

  const renderedUpcomingSubmissions =
    upcomingSubmissions.length === 0 ? (
      <>None to display</>
    ) : (
      upcomingSubmissions.map((submission) => {
        return <AssignmentCard submission={submission} isUpcoming={true} />;
      })
    );

  // Make past submissions (past exams) section 100% height if student under individual subscription (not school)
  const pastSubmissionsHeightStyling = {
    height: `${user?.subscription.type === "school" ? 50 : 100}%`,
  };

  return (
    <div id="assignment-sidebar">
      <div
        className="past-submissions-wrapper"
        style={pastSubmissionsHeightStyling}
      >
        <h3>
          {user?.subscription.type === "school"
            ? "Past Submissions"
            : "Past Practice"}
        </h3>
        <div className="submissions-internal-wrapper">
          {renderedPastSubmissions}
        </div>
      </div>

      {/* only show upcoming submissions for students under school subscription (not individual) */}
      {user?.subscription.type === "school" && (
        <div className="upcoming-submissions-wrapper">
          <h3>Upcoming Submissions</h3>
          <div className="submissions-internal-wrapper">
            {renderedUpcomingSubmissions}
          </div>
        </div>
      )}
    </div>
  );
};

export default AssignmentSidebar;
