import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // TODO: use env var based on environment in which this app is running
  withCredentials: true, // Always send cookies or credentials in requests
});

// Add a response interceptor
axiosClient.interceptors.response.use(
  // Handle successful responses
  (response) => {
    return response;
  },
  // Handle errors
  async (error) => {
    // Handle 401 errors here, for example, redirect to login page
    if (error.response.status === 401) {
      // TODO: use env var for BASE URL
      // Check not on login page to avoid infinite loop
      if (window.location.href !== `${process.env.REACT_APP_SITE_URL}/login`) {
        alert("Your session has expired. Please login again.");
        window.location.href = `${process.env.REACT_APP_SITE_URL}/login`; // TODO: use env var for BASE URL
      }
    }
    return Promise.reject(error);
  },
);

export default axiosClient;
