import { ReactElement, useLayoutEffect, useState } from 'react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

export const ZoomImage = (props: {
    src: string,
    alt?: string,
    width: number,
    centered?: boolean
}) => (
  <Zoom ZoomContent={CustomZoomContent}>
    <div style={{
        display: 'grid'
    }}>
    <img
      alt={props.alt}
      src={props.src}
      width={props.width || 300}
      style={{
        borderRadius: '5px',
        margin: props.centered ? 'auto' : undefined,
        marginBottom: '10px'
      }}
    />

    </div>

  </Zoom>
)

const CustomZoomContent = (props: {
    buttonUnzoom: ReactElement<HTMLButtonElement>, // default unzoom button
    modalState: 'UNLOADED' | 'LOADING' | 'LOADED' | 'UNLOADING',   // current state of the zoom modal: UNLOADED, LOADING, LOADED, UNLOADING
    img: ReactElement | null, // your image, prepped for zooming
    onUnzoom?: () => void,
    // unused here, but a callback to manually unzoom the image and
    //   close the modal if you want to use your own buttons or
    //   listeners in your custom experience
  }) => {
    const [isLoaded, setIsLoaded] = useState(false)
  
    useLayoutEffect(() => {
      if (props.modalState === 'LOADED') {
        setIsLoaded(true)
      } else if (props.modalState === 'UNLOADING') {
        setIsLoaded(false)
      }
    }, [props.modalState])
  
    const classCaption = isLoaded
      ? 'zoom-caption zoom-caption--loaded'
      : 'zoom-caption'
  
    return <div>
    
    <div onClick={(e) => e.stopPropagation()}>
        {props.buttonUnzoom}
    </div>
  
      <figure>
        {props.img}
        {/* <figcaption className={classCaption}>
          That Wanaka Tree, also known as the Wanaka Willow, is a willow tree
          located at the southern end of Lake Wānaka in the Otago region of New
          Zealand.
          <cite className="zoom-caption-cite">
            Wikipedia, <a className="zoom-caption-link" href="https://en.wikipedia.org/wiki/That_Wanaka_Tree">
              That Wanaka Tree
            </a>
          </cite>
        </figcaption> */}
      </figure>
    </div>
  }