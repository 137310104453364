import ReactPaginate from "react-paginate"
import Button from "../../components/Button/Button";

export const AssignmentPageFooter = (props: {
    handleQuestionChange: (e: {
        selected: number;
    }) => void;
    totalQuestions: number;
    currentQuestionIndex: number;
}) => {

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                {/* <ReactPaginate
                    nextLabel="next >"
                    onPageChange={props.handleQuestionChange}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={3}
                    pageCount={props.totalQuestions}
                    previousLabel="< previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    forcePage={props.currentQuestionIndex}
                /> */}
                <div></div>
                <div style={{
                    display: 'flex'
                }}>
                    <Button
                        text="Previous"
                        disabled={props.currentQuestionIndex === 0}
                        onClick={() => {
                            props.handleQuestionChange({
                                selected: props.currentQuestionIndex - 1,
                            });
                        }}
                    ></Button>
                    <Button
                        text="Next"
                        disabled={props.currentQuestionIndex === props.totalQuestions - 1}
                        onClick={() => {
                            props.handleQuestionChange({
                                selected: props.currentQuestionIndex + 1,
                            });
                        }}
                    ></Button>

                </div>
            </div>
        </>
    )
}
