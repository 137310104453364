import { useUser } from "../../context/UserContext";
import "./Topbar.scss";

import { AiFillBell } from "react-icons/ai";
import profileIcon from "../../assets/profile-icon.svg";
import { Dropdown, Image, NavDropdown } from "react-bootstrap";

export const Topbar = (props: { title: string }): JSX.Element => {
  const { user, logout } = useUser();
  const iconSize = 20;
  const iconColour = "#333333";

  const UserMenu = (
    <Image
      src={profileIcon}
      alt="UserName profile image"
      roundedCircle
      style={{ width: "30px" }}
    />
  );

  return (
    <div id="topBar">
      <h1>{props.title}</h1>
      <AiFillBell
        id="bell"
        color={iconColour}
        size={iconSize}
        style={{
          visibility: "hidden",
        }}
      />
      <div id="profileAreaWrapper">
        <div id="profileArea">
          <NavDropdown id="nav-dropdown" title={UserMenu}>
            <Dropdown.Header>{user?.firstName} {user?.lastName}</Dropdown.Header>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="1" onClick={logout}>Logout</Dropdown.Item>
          </NavDropdown>
        </div>
      </div>
    </div>
  );
};
