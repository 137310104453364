import Sidebar from "../../components/Sidebar/Sidebar";
import { Topbar } from "../../components/Topbar/Topbar";
import { useLocation, useNavigate } from "react-router-dom";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import { useEffect, useRef, useState } from "react";
// NOTE: uses scss from AssignmentPage component
import "../AssignmentPage/AssignmentPage.scss";
import axiosClient from "../../axiosClient";
import { Assignment, Question } from "../../types";
import { ZoomImage } from "../../components/ZoomImage/ZoomImage";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { ImCross } from "react-icons/im";
import PracticePageSidebar from "../../components/PracticePageSidebar/PracticePageSidebar";
import { PracticePageFooter } from "./PracticePageFooter";
import { AssignmentPageTitle } from "../AssignmentPage/AssignmentPageTitle";

const PracticePage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [questions, setQuestions] = useState<Question[]>([]);
    const [submittedPracticeQuestions, setSubmittedPracticeQuestions] = useState<Question[]>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    // const [currentQuestion, setCurrentQuestion] = useState<Question>();
    // selectedAnswers maps each questionId to the answerId chosen
    const [selectedAnswers, setSelectedAnswers] = useState<{
        [key: number]: number;
    }>({});

    // only used in practice mode
    const [practiceQuestionAnswered, setPracticeQuestionAnswered] =
        useState<boolean>(false);
    const [
        totalNumberOfPracticeQuestionsInModule,
        setTotalNumberOfPracticeQuestionsInModule,
    ] = useState<number>(0);
    const [practiceQuestionsAnsweredCount, setPracticeQuestionsAnsweredCount] =
        useState(0);
    const navigate = useNavigate();
    const data = useLocation();

    const getAssignmentTitle = () => data?.state?.assignmentName;
    const getPracticeModuleId = () => data?.state?.practiceModuleId;
    const getPracticeSubjectId = () => data?.state?.practiceSubjectId;
    const getExamBoards = (): string[] => data?.state?.examBoards;

    useEffect(() => {
        let submittedSelectedAnswers: {
            [key: number]: number;
        } = {};

        submittedPracticeQuestions.forEach(submittedPracticeQuestion => {
            const submittedSelectedAnswer = (submittedPracticeQuestion.answerOptions.filter(answerOption => answerOption.selected))[0];
            if (submittedSelectedAnswer) {
                submittedSelectedAnswers[submittedPracticeQuestion.id] = submittedSelectedAnswer.id;
            }
        })

        setSelectedAnswers({
            ...selectedAnswers,
            ...submittedSelectedAnswers
        })
    }, [submittedPracticeQuestions])


    useEffect(() => {
        setPracticeQuestionAnswered(currentQuestionIndex < 0);
    }, [currentQuestionIndex])


    // TODO: consider refactoring this code to remove duplication shared between this method and getNextBatchOfPracticeQuestions
    const getPreviouslySubmittedPracticeQuestions = async () => {
        setIsLoading(true);

        let queryParam = `?moduleId=${getPracticeModuleId()}`;
        const examBoards = getExamBoards();
        if (examBoards.length > 0) {
            queryParam += `&examBoards=${examBoards.join(',')}`
        }

        const submittedPracticeQuestionsResponse = (
            await axiosClient.get(`/practice-questions-completed${queryParam}`)
        ).data;
        setSubmittedPracticeQuestions(submittedPracticeQuestionsResponse.questions);
        getNextBatchOfPracticeQuestions();
        setIsLoading(false);
    };

    // TODO: cover use case of all questions practiced and the student gets initial questions again.
    const getNextBatchOfPracticeQuestions = async (calledOnPageLoad = true) => {
        setIsLoading(true);
        let queryParam = getPracticeModuleId()
            ? `?moduleId=${getPracticeModuleId()}`
            : `?subjectId=${getPracticeSubjectId()}`;

        const examBoards = getExamBoards();
        if (examBoards.length > 0) {
            queryParam += `&examBoards=${examBoards.join(',')}`
        }

        const practiceQuestionsResponse = (
            await axiosClient.get(`/questions${queryParam}`)
        ).data;
        const practiceQuestions: Question[] = practiceQuestionsResponse.questions;
        const answeredQuestionsCount =
            practiceQuestionsResponse.answeredQuestionsCount;

        // Only set the `practiceQuestionsAnsweredCount` on first page load of practice mode,
        // and not when re-querying for questions 11-20, 21-30, 31-40, 41-50, etc...
        // The reason for this is to ensure that 'X' in 'Question X of Y' renders correctly
        // because in such a scenario the currentQuestionIndex will be 10-19, 20-29, 30-39, 40-49, etc...
        // and 'X' is set as currentQuestionIndex + 1 + practiceQuestionsAnsweredCount
        if (questions.length === 0) {
            setPracticeQuestionsAnsweredCount(answeredQuestionsCount);
        }

        setTotalNumberOfPracticeQuestionsInModule(
            practiceQuestionsResponse.totalNumberOfQuestions,
        );

        if (calledOnPageLoad) {
            console.log('got here 111')
            setQuestions(practiceQuestions);
        }
        else {
            console.log('got here 222')
            setQuestions(questions.concat(practiceQuestions));
        }
        setIsLoading(false);
    };

    useEffect(() => {
        const getQuestions = async () => {
            try {
                await getPreviouslySubmittedPracticeQuestions();
            } catch (err) {
                alert("Error loading assignment. Please seek support!");
                console.error(err);
            }
        };
        getQuestions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isLoading && questions.length > 0) {
            // clear all checkboxes
            document
                .querySelectorAll('input[type="checkbox"]')
                .forEach((checkbox) => {
                    (checkbox as HTMLInputElement).checked = false;
                });

            document
                .querySelectorAll('.answer-option')
                .forEach((answerOption) => {
                    (answerOption as HTMLDivElement).classList.remove('selected-answer-option');
                });

            let currentQuestion = getCurrentQuestion();
            // if navigating to a question already answered, re-check the necessary checkbox
            if (currentQuestion.id in selectedAnswers) {
                const selectedAnswerId = selectedAnswers[currentQuestion.id];
                const selectedAnswer = document.getElementById(
                    selectedAnswerId.toString(),
                );
                const checkbox = selectedAnswer?.querySelector(
                    'input[type="checkbox"]',
                );

                if (checkbox) {
                    selectedAnswer?.classList.add('selected-answer-option');
                    (checkbox as HTMLInputElement).checked = true;
                }
            }
        }
    });

    if (isLoading) {
        return (
            <div className="page-wrapper" id="PracticePage">
                <Sidebar />
                <div className="internal-page-wrapper">Loading...</div>
            </div>
        );
    }

    const handleQuestionChangePracticeMode = async () => {
        if (
            currentQuestionIndex + practiceQuestionsAnsweredCount ===
            totalNumberOfPracticeQuestionsInModule - 1
        ) {
            // don't bother calling out to backend - just set current index to 0, and the screen should update to the 'well done' screen.
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            return;
        }

        if (currentQuestionIndex >= questions.length - 1) {
            await getNextBatchOfPracticeQuestions(false);
        }

        setCurrentQuestionIndex(currentQuestionIndex + 1);
        if (practiceQuestionAnswered) {
            setPracticeQuestionAnswered(false);
        }
    };

    const handleAnswerSelected = (e: any) => {
        const userClickedOnSurroundingDivToZoomOutOfImage = e.target.hasAttribute("data-rmiz-modal-content");
        // Avoid clicks on the image from changing the checked value, because clicking on the image
        // is done to enlarge it, not necessarily to select that answer.
        // ALSO avoid clicks on the div surrounding the enlarged image from changing the checked value.
        if (practiceQuestionAnswered || e.target.tagName === 'IMG' || userClickedOnSurroundingDivToZoomOutOfImage) {
            return;
        }

        // See https://stackoverflow.com/a/53815609 for why we use e.currentTarget instead of e.target
        const selectedCard = e.currentTarget as Element;

        selectedCard.parentElement
            ?.querySelectorAll('.answer-option')
            .forEach((answerOption) => {
                (answerOption as HTMLDivElement).classList.remove('selected-answer-option');
            });

        const checkboxInsideCard = selectedCard.querySelector(
            'input[type="checkbox"]',
        ) as HTMLInputElement;

        selectedCard.parentElement
            ?.querySelectorAll('input[type="checkbox"]')
            .forEach((checkbox) => {
                if (checkbox !== checkboxInsideCard) {
                    (checkbox as HTMLInputElement).checked = false;
                }
            });

        // Only force the checkbox to change value when clicking on the card (not the checkbox itself),
        // because when the checkbox itself is clicked it auto changes value, which means the statement
        // below causes the click on the checkbox to cause no change at all, which is confusing.
        if (e.target.tagName !== 'INPUT') {
            checkboxInsideCard.checked = !checkboxInsideCard.checked;
        }

        if (checkboxInsideCard.checked) {
            selectedCard.classList.add('selected-answer-option')
            const updatedAnswers = {
                ...selectedAnswers,
            };
            updatedAnswers[currentQuestion.id] = Number(selectedCard.id);
            setSelectedAnswers(updatedAnswers);
        } else {
            const updatedAnswers = {
                ...selectedAnswers,
            };
            delete updatedAnswers[currentQuestion.id];
            setSelectedAnswers(updatedAnswers);
        }
    };

    // TODO: rename this method
    const submitPracticeModeSingleQuestion = async () => {
        const currentQuestionId = questions[currentQuestionIndex].id;
        const selectedAnswerInPracticeMode = selectedAnswers[currentQuestionId];
        // currentQuestionIndex will always be 0 here
        const currentQuestion = questions[currentQuestionIndex];

        // MAKE CALLOUT HERE - submission id should be auto-set as null in the backend. TODO: double-check this??
        const payload = {
            questionId: currentQuestionId,
            answerOptionId: selectedAnswers[currentQuestionId],
        };

        await axiosClient.post("/question-submit", payload);

        // remove first question from questions
        const updatedPracticeQuestions = [...questions].slice(1);
        if (updatedPracticeQuestions.length > 0) {
            setQuestions(updatedPracticeQuestions);
        }

        else {
            // this callout must be made AFTER the POST to /question-submit above, to ensure the 10th question we just did
            // is NOT included in the next batch of 10
            getNextBatchOfPracticeQuestions();
        }

        if (!selectedAnswerInPracticeMode) {
            alert('Please select an answer!');
            return;
        }

        // TODO: see if there is a quicker way to update the answered question in place, much more elegantly, instead
        // of having to work through the whole questions and answer options state.
        const updatedAnswerOptions = currentQuestion.answerOptions.map(
            (answerOption) => {
                if (answerOption.id !== selectedAnswerInPracticeMode) {
                    return answerOption;
                } else {
                    return {
                        ...answerOption,
                        selected: true,
                    };
                }
            },
        );

        const updatedCurrentQuestion = {
            ...currentQuestion,
            answerOptions: [...updatedAnswerOptions],
        };

        // const updatedQuestions = questions.map((q) => {
        //     if (q.id !== updatedCurrentQuestion.id) {
        //         return q;
        //     } else {
        //         return updatedCurrentQuestion;
        //     }
        // });

        // setQuestions(updatedQuestions);

        // append the removed question to the submittedPracticeQuestions array
        const updatedSubmittedPracticeQuestions = [updatedCurrentQuestion, ...submittedPracticeQuestions]
        setSubmittedPracticeQuestions(updatedSubmittedPracticeQuestions)
        setPracticeQuestionsAnsweredCount(practiceQuestionsAnsweredCount + 1);
        setPracticeQuestionAnswered(true);
        setCurrentQuestionIndex(-1);
    };

    if (practiceQuestionsAnsweredCount === totalNumberOfPracticeQuestionsInModule && currentQuestionIndex === 0) {
        return (
            <div className="page-wrapper" id="PracticePage">
            <Sidebar />
            <div className="internal-page-wrapper">
                <Topbar title={getAssignmentTitle()} />
                <main>
                    <div className="inner-main-wrapper row">
                        <div className="inner-max-width-wrapper col-10">
                            <p>Well done, module complete 🎉🎉! You've finished all this module's questions. Restart?</p>
                        </div>
                        <div className="col-2">
                            {<PracticePageSidebar
                                submittedPracticeQuestions={submittedPracticeQuestions}
                                onSubmittedQuestionClicked={(index: number) => {
                                    setCurrentQuestionIndex(- index - 1)
                                }}
                                reverseQuestionNumbering
                            />}
                        </div>
                    </div>
                </main>
            </div>
        </div>
        );
    }

    const getCurrentQuestion = () : Question => {
        if (currentQuestionIndex < 0) {
            return submittedPracticeQuestions[Math.abs(currentQuestionIndex) - 1]
        } else {
            return questions[currentQuestionIndex];
        }
    }

    const ABCDE = "ABCDE";

    let currentQuestion: Question = getCurrentQuestion();

    const answeredCorrectly =
        currentQuestion.answerOptions.filter(
            (answerOption) => answerOption.isCorrect && answerOption.selected,
        ).length === 1;

    const delimeters = [
        {
            // NOTE: `display` is by default `true` for the '$$' delimeter option
            // out the box from the react-latex-next package. However, I've set it
            // to `false` to ensure it doesn't render the equation in the centre
            // of the parent. Instead, `false` ensures the equation is rendered
            // in-line with the text. I should also point out that I could have just
            // used the single '$' delimeter option, where all questions with equations
            // have the equation wrapped with a single '$' either side, but I thought
            // this was risky if by accident a question has a $ sign in the question
            // text itself. In such a case, we might not realise, and the question
            // would render incorrectly. Thus, I have opted for the '$$' approach,
            // despite it meaning that we have to manually define the delimeters in
            // our implementation (which wouldn't have been the case otherwise).
            // See: https://www.npmjs.com/package/react-latex-next
            display: false,
            left: "$$",
            right: "$$",
        },
    ];

    const explanationLines = currentQuestion.explanation?.text
        ?.split("\\n")
        .map((line) => (
            <p className="explanation-line">
                <Latex delimiters={delimeters}>{line}</Latex>
            </p>
        ));

    // TODO: rename footer component

    return (
        <div className="page-wrapper" id="PracticePage">
            <Sidebar />
            <div className="internal-page-wrapper">
                <Topbar title={getAssignmentTitle()} />
                <main>
                    {/* <button onClick={() => {console.log(questions)}}>log questions</button> */}
                    <div className="inner-main-wrapper row">
                        <div className="inner-max-width-wrapper col-10">
                            <div className="top-area">
                                {/* rename this component - maybe QuestionMetadata? */}
                                <AssignmentPageTitle
                                    questionId={currentQuestion.id}
                                    questionNumber={currentQuestionIndex + 1 + practiceQuestionsAnsweredCount}
                                    totalQuestions={totalNumberOfPracticeQuestionsInModule}
                                    isAssignmentMode={false}
                                />
                            </div>
                            <div id="scroller">
                                <h5 className="questionTitle">
                                    <Latex delimiters={delimeters}>{currentQuestion.text}</Latex>
                                </h5>
                                {currentQuestion.imageUrl && (
                                    <div style={{
                                        marginBottom: '50px'
                                    }}>
                                        <ZoomImage src={currentQuestion.imageUrl} width={300}></ZoomImage>
                                    </div>
                                )}

                                {/* <div className='questionImage'></div> */}

                                <div className="answer-options-wrapper">
                                    {currentQuestion?.answerOptions.map((answerOption, index) => {
                                        const option = ABCDE[index];
                                        const classes = ["answer-option"];
                                        if (
                                            practiceQuestionAnswered
                                        ) {
                                            classes.push("locked");

                                            if (answerOption.selected) {
                                                // gives it the blue outline
                                                classes.push('selected-answer-option');
                                            }
                                        }
                                        return (
                                            <div
                                                className={classes.join(" ")}
                                                onClick={handleAnswerSelected}
                                                id={answerOption.id.toString()}
                                            >
                                                <div>
                                                    <div className="top-part">
                                                        <label htmlFor={option}>{option}</label>

                                                        {practiceQuestionAnswered && answerOption.isCorrect && <IoIosCheckmarkCircle color="green" size={25} />}
                                                        {practiceQuestionAnswered && !answerOption.isCorrect && <ImCross color="white" size={20} style={{
                                                            backgroundColor: 'red',
                                                            borderRadius: '10px',
                                                            padding: '5px'
                                                        }} />}

                                                        <input
                                                            type="checkbox"
                                                            disabled={
                                                                practiceQuestionAnswered
                                                            }
                                                            name={option}
                                                            id={option}
                                                            style={{
                                                                display: (practiceQuestionAnswered) ? 'none' : 'block'
                                                            }}
                                                        />
                                                    </div>
                                                    <hr />
                                                    <div className="bottom-part">
                                                        <p>
                                                            <Latex delimiters={delimeters}>
                                                                {answerOption.text}
                                                            </Latex>
                                                        </p>
                                                        {answerOption.imageUrl && <ZoomImage src={answerOption.imageUrl} width={200}></ZoomImage>}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                                {practiceQuestionAnswered ? (
                                    <div
                                        className={
                                            answeredCorrectly
                                                ? "explanation explanation-correct"
                                                : "explanation explanation-incorrect"
                                        }
                                    >
                                        <div className="explanation-title">
                                            {answeredCorrectly ? <span>Correct! <IoIosCheckmarkCircle color="green" size={25} style={{
                                                position: 'relative',
                                                bottom: '2px'
                                            }} /></span> : <span>Incorrect! <ImCross color="white" size={20} style={{
                                                backgroundColor: 'red',
                                                borderRadius: '10px',
                                                padding: '5px',
                                                position: 'relative',
                                                bottom: '2px'
                                            }} /></span>}
                                        </div>
                                        {explanationLines}
                                        <div style={{
                                            display: 'flex',
                                            gap: 20,
                                            marginTop: 20
                                        }}>
                                            {currentQuestion.explanation?.images.map(image => {
                                                return <ZoomImage src={image.url} width={150}></ZoomImage>
                                            })}
                                        </div>

                                    </div>
                                ) : undefined}

                                <PracticePageFooter
                                    // currentQuestionIndex={currentQuestionIndex}
                                    // practiceQuestionsAnsweredCount={practiceQuestionsAnsweredCount}
                                    // totalQuestions={totalNumberOfPracticeQuestionsInModule}
                                    submitPracticeModeSingleQuestion={submitPracticeModeSingleQuestion}
                                    submitButtonDisabled={practiceQuestionAnswered}
                                    handleQuestionChangePracticeMode={handleQuestionChangePracticeMode}
                                    nextButtonDisabled={!practiceQuestionAnswered}
                                />
                                {/* <Editor initialContent={currentQuestion.explanation?.text} /> */}
                            </div>
                        </div>
                        <div className="col-2">
                            {<PracticePageSidebar
                                submittedPracticeQuestions={submittedPracticeQuestions}
                                onSubmittedQuestionClicked={(index: number) => {
                                    setCurrentQuestionIndex(- index - 1)
                                }}
                                reverseQuestionNumbering
                            />}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default PracticePage;
