import { useState, useRef } from "react";
import "./DragAndDropFileUploader.scss";
import Button from "../Button/Button";
import Papa from "papaparse";

const DragAndDropFileUploader = (props: {
  setData: (data: any[]) => void;
  expectedColumnTitles: string[];
  accept: string;
}) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // at least one file has been dropped so do something.
      // `true` is used as a second optional parameter telling the `handleChange()` function that
      // the file was dropped, so it should be read from `e.dataTransfer.files[0]` instead of
      // `e.dataTransfer.files[0]` which is used when the file is uploaded manually using the form.
      handleChange(e, true);
    }
  };

  // triggers the input when the button is clicked
  const onUploadButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  function camelize(str: string) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }

  // triggers when file is selected with click
  const handleChange = async function (e: any, wasDropped = false) {
    e.preventDefault();

    const validFile = wasDropped
      ? e.dataTransfer.files && e.dataTransfer.files[0]
      : e.target.files && e.target.files[0];

    if (validFile) {
      const file = wasDropped ? e.dataTransfer.files[0] : e.target.files[0];
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header) => camelize(header),
        complete: function (results: any) {
          const columnTitles: string = Object.keys(results.data[0]).toString();
          const validColumnTitles =
            columnTitles ===
            props.expectedColumnTitles
              .map((columnTitle) => camelize(columnTitle))
              .join(",");

          if (!validColumnTitles) {
            alert(
              "Invalid csv file format. Please ensure the columns titles are identical to those in the template file!",
            );
            return;
          }

          props.setData(results.data);
        },
      });
    }
  };

  return (
    <form
      id="DragAndDropFileUploader"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        accept={props.accept}
        id="input-file-upload"
        multiple={true}
        onChange={handleChange}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? "drag-active" : ""}
      >
        <div>
          <p>Drag and drop your file here or</p>
          <Button text="Upload a file" onClick={onUploadButtonClick} />
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
};

export default DragAndDropFileUploader;
