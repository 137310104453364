import { Button } from "react-bootstrap";
import { ImCross } from "react-icons/im"
import { IoIosCheckmarkCircle } from "react-icons/io"
import { Question } from "../../types";

const PracticePageSidebar = (props: {
    submittedPracticeQuestions: Question[];
    onSubmittedQuestionClicked: (index: number) => void;
    reverseQuestionNumbering?: boolean;
}) => {

    const numberOfSubmittedPracticeQuestions = props.submittedPracticeQuestions.length;
    const numberCorrect = props.submittedPracticeQuestions.filter(q => q.answerOptions.filter(a => a.selected && a.isCorrect).length > 0).length
    const numberIncorrect = numberOfSubmittedPracticeQuestions - numberCorrect;

    const percentage = numberOfSubmittedPracticeQuestions === 0 ? 0 : (numberCorrect / numberOfSubmittedPracticeQuestions * 100).toFixed(0);

    return (
        <div className="questions-progress-tracker">

            <div className="sticky-top-part">

                <h5 className="mb-4">Progress <span style={{ float: 'right' }}>{percentage}%</span></h5>
                <ul>
                    <li>
                        <IoIosCheckmarkCircle color="green" size={20} /> Correct <span className="progress-total-correct">{numberCorrect}</span>
                    </li>
                    <li>
                        <ImCross color="white" size={18} style={{
                            backgroundColor: 'red',
                            borderRadius: '10px',
                            padding: '5px'
                        }} /> Incorrect <span className="progress-total-incorrect">{numberIncorrect}</span>
                    </li>
                    {/* <li>
                        <BsQuestionCircleFill color="orange" size={18} /> Skipped <span className="progress-total-skipped">3</span>
                    </li> */}
                </ul>
            </div>

            <div className="past-questions-tracker">
                <ul>
                    {props.submittedPracticeQuestions.map((submittedPracticeQuestion, index) => {
                        const questionNumber = props.reverseQuestionNumbering ? numberOfSubmittedPracticeQuestions - index : index + 1;
                        if (submittedPracticeQuestion.answerOptions.filter(answer => answer.selected && answer.isCorrect).length > 0) {
                            return (
                                <li>
                                    <Button onClick={() => {
                                        props.onSubmittedQuestionClicked(index)
                                    }} size="sm" variant="outline-success"><IoIosCheckmarkCircle size={20} /> Q{questionNumber}</Button>
                                </li>
                            )
                        } else {
                            return (
                                <li>
                                    <Button onClick={() => {
                                        props.onSubmittedQuestionClicked(index)
                                    }} size="sm" variant="outline-danger"><ImCross color="white" size={18} style={{
                                        backgroundColor: 'red',
                                        borderRadius: '10px',
                                        padding: '5px'
                                    }} /> Q{questionNumber}</Button>
                                </li>
                            )
                        }
                    })}
                </ul>

                {/* <Button size="sm" variant="outline-dark">Load more...</Button> */}
            </div>
        </div>
    )
}

export default PracticePageSidebar;