import { useEffect } from "react";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";

function TermsAndConditions() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      <div id="TermsAndConditions">
        <div className="site-container" style={{
            marginTop: '50px'
        }}>
            <h1>Terms and Conditions</h1>
            <p><strong>BY REGISTERING AND/OR USING OUR SERVICES, YOU AGREE TO THE TERMS AND CONDITIONS BELOW. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, YOU MUST NOT USE OUR SERVICES.</strong></p>
<p>If you have any questions, email us at&nbsp;<a href="mailto:support@masterq.co.uk">support@masterq.co.uk</a></p>
<p><strong>Updated December 2022</strong></p>
<p>Please read these terms and conditions of service (the &ldquo;<strong>Terms</strong>&ldquo;) together with the&nbsp;<a href="https://www.masterq.co.uk/privacy-policy/">Data and Privacy Policy</a>&nbsp;(as amended from time to time).</p>
<p>These Terms (including any updates from time to time) govern Your use of Our platform via Our website (&ldquo;<a href="http://www.masterq.co.uk/">www.masterq.co.uk</a>&ldquo;) (the &ldquo;<strong>Website</strong>&ldquo;), our MasterQ app (the &ldquo;<strong>App</strong>&ldquo;) and the services We provide to You via the Website and/or App (together, the &ldquo;<strong>Services</strong>&rdquo;).</p>
<p><strong>Who Are We?</strong></p>
<p>We are MasterQ Ltd, a limited company registered in England and Wales.</p>
<p><strong>Our Agreement with You</strong></p>
<p>These Terms apply to any use of Our Services by a school (which may include any local authority or other entity with ultimate responsibility for or control over the school) (&ldquo;<strong>School</strong>&ldquo;), its teachers, staff and any other authorised representatives (&ldquo;<strong>Users</strong>&ldquo;) (the School and Users together are referred to as &ldquo;<strong>You</strong>&rdquo; and &ldquo;<strong>Your</strong>&ldquo;). Where an individual agrees to these terms for and on behalf of a School, the individual warrants that he/she has the authority to do so and to contractually bind the School.</p>
<p>As part of Our Services, You are permitted to grant access to Our Services to Your students enrolled at the School (&ldquo;Students&rdquo;) subject to the Students accepting the Terms of Use (as updated from time to time). Notwithstanding the foregoing, You agree to be responsible for Your Students&rsquo; use of the Services.</p>
<p>Please keep a copy of these Terms for future reference. We may change these Terms for legal, regulatory or security reasons or for any other reason by giving You notice by email or via updates or posting via Our Website or App or by asking You to re-confirm Your acceptance of the Terms. Your continued use of Our Services following such notice will be deemed Your acceptance of the amended Terms.</p>
<p><strong>App Users</strong></p>
<p>You acknowledge that Our agreement is with You. App store providers such as Apple and Google are not a party to these Terms and have no responsibility for the App or its content.</p>
<p>In these Terms, We refer to the site that You download the App from as the &lsquo;app store&rsquo; and We refer to their rules and policies as the &lsquo;app store rules&rsquo;. You must comply with the app store rules as well as these Terms but, if there is any conflict between them, You should follow the app store rules rather than the equivalent rule here. If You have downloaded the App through Apple&rsquo;s app store, You may be referred to as an &lsquo;iOS User&rsquo; in these Terms and related references to the &lsquo;app store&rsquo; and &lsquo;app store rules&rsquo; means those provided by Apple, including the Apple Media Services Terms and Conditions.</p>
<p>You do not own the App or any of its contents but You may use it on devices that You own or control (and in the case of iOS Users, on Apple-branded products only), as permitted by the app store rules.</p>
<p>If You sell or give away the device on which You have downloaded the App, You must first remove the App from the device.</p>
<p>You are not allowed to:</p>
<ul>
<li>hack the App (which might include accessing its code, inserting new code, or modifying the way it functions in any way); or</li>
<li>pretend that the App is Your own or make it available for others to download or use.</li>
</ul>
<p><strong>1.YOUR LEGAL OBLIGATIONS AND PROMISES TO US</strong></p>
<p>1.1.You confirm that:</p>
<p>1.1.1.all information and details provided by You are true, accurate and up to date. The rights granted under these Terms are personal to You and You must not make the Services available to anyone else (unless expressly permitted under these Terms); and</p>
<p>1.1.2.You will comply with the Rules of Conduct relating to Your use of the Services as set out in Clause&nbsp;4&nbsp;below, and You will comply with any other restrictions set out elsewhere in the Terms.</p>
<p>1.2.You agree to compensate Us where We pay damages or incur any other loss or expenses (including any legal fees in relation to such claim or damages) following any claim made by a third party in respect of any matter in relation to or arising from Your (or Your Students&rsquo;) use of the Services including any breach of these Terms or Your violation of any law or the rights of a third party.</p>
<p>1.3.If We take legal action against You for non-payment or any other breach of these Terms and a court makes an award in Our favour, You will be responsible for all costs allowable by the courts.</p>
<p><strong>2.STANDARD SUBSCRIPTION CONTRACT TERMS</strong></p>
<p>2.1.Subject to Your payment of the Charges in accordance with clause&nbsp;8&nbsp;and the restrictions set out in these Terms, We hereby grant to You a non-exclusive, non-transferable right to permit the Users and Students to use the Services during the Subscription Term.</p>
<p>2.2.The licence provided to You in accordance with clause&nbsp;2.1&nbsp;allows the assigned Students and Users selected by You in accordance with clause&nbsp;2.3&nbsp;the right to use the Services at home or school (together, &ldquo;<strong>Authorised Users</strong>&rdquo;).</p>
<p>2.3.Authorised Users must be Students and Users enrolled or working (as applicable) at Your School (or, in the case of Multi-Academy Schools (MAT), at Schools within Your MAT). You cannot purchase one licence to use across several Schools unless agreed in writing with Us. You will have access to curriculum, content or tools available within the product(s) at no extra charge.</p>
<p>2.4.Our Services are continuously evolving. We will endeavour to provide You with telephone and email support services. Contact details for such support can be found on our website or in the footer.</p>
<p><strong>Trial period</strong></p>
<p>2.5.If You sign up for a free trial account, You shall have a limited, personal, non-transferable right to access the Service for internal evaluation purposes only for 30 days (the &ldquo;Trial Period&rdquo;). On expiry of the Trial Period, Your access to the Services shall be terminated unless and until a full subscription is activated. Only one free trial is permitted per school.</p>
<p>2.6.During the Trial Period, the provisions of these Terms shall apply, save as follows:</p>
<p>2.6.1.You shall have no obligation to pay the Charges in respect of the Trial Period only;</p>
<p>2.6.2.either party may terminate the supply of the Services immediately by giving written notice to the other party at any time before the end of the Trial Period.</p>
<p><strong>3.YOUR ACCOUNT / USE OF THE SERVICES</strong></p>
<p>3.1.Use of the Services may require You to create an account. If You don&rsquo;t already have an account and such account is required, You will be prompted to set one up before using the Website or App (&ldquo;<strong>Account</strong>&ldquo;). Your access to the Services will be linked to Your Account and You are required to comply with the requirements set out in these Terms.</p>
<p>3.2.If any information You provide in connection with Your Account is untrue, inaccurate, out-of-date or incomplete then We reserve the right to refuse Your current and future use of the Services. We shall not be liable for any failure or delay in the provision of the Services as a result of Your failure to provide accurate or up-to-date information.</p>
<p>3.3.We do not have the means to check the identities of people using the Services and will not be liable where Your Account is used by someone else. You agree to notify Us immediately if You become aware of any unauthorised use of Your Account. We reserve the right to suspend or terminate the Services if We suspect unauthorised use of Your Accounts.</p>
<p>3.4.In relation to the Authorised Users, You undertake that:</p>
<p>3.4.1.You will not allow or suffer any Account (as defined in Clause&nbsp;3.1) to be used by more than one individual Authorised User unless it has been reassigned in its entirety to another individual Authorised User, in which case the prior Authorised User shall no longer have any right to access or use the Services;</p>
<p>3.4.2.each Authorised User shall keep a secure password for their use of the Services, that such password shall be changed at reasonable intervals and that each Authorised User shall keep their password confidential;</p>
<p>3.4.3.You shall permit Us to audit the Services in order to establish the name and password of each Authorised User;</p>
<p>3.4.4.if any of the audits referred to in clause&nbsp;3.4.3&nbsp;reveal that any password has been provided to any individual who is not an Authorised User, then without prejudice to Our other rights, You shall promptly disable such passwords and We shall not issue any new passwords to any such individual; and</p>
<p>3.4.5.if any of the audits referred to in clause&nbsp;3.4.3&nbsp;reveal that You have underpaid Charges to Us, then without prejudice to Our other rights, You shall pay to Us an amount equal to such underpayment as calculated in accordance with Our standard charges at the time within 10 business days of the date of the relevant audit.</p>
<p>3.5.We reserve the right to remove any content from the Services at Our sole discretion.</p>
<p><strong>4.RULES OF CONDUCT AND YOUR USE OF THE SERVICES</strong></p>
<p>4.1.You agree that in using the Services You will not:</p>
<p>4.1.1.use the Services for any purpose other than the stated commercial use;</p>
<p>4.1.2.reproduce, distribute, publicly display or perform, translate, modify, adapt, create derivate works from, deconstruct, reverse engineer, decompile or disassemble, in any manner the Services or any portion thereof;</p>
<p>4.1.3.access or attempt to access the accounts of other users or to penetrate or attempt to penetrate Our security measures;</p>
<p>4.1.4.partake in any behaviour that We deem inappropriate and disruptive, or against the tone and nature of the Services (including but not limited to where user-generated content or an online community exists);</p>
<p>4.1.5.harass, threaten, embarrass, spam or do anything else to another person that is unwanted, such as repeatedly sending unwanted messages or making personal attacks or statements about race, nationality, gender, age, health, sexual orientation or religion (including but not limited to where user-generated content or an online community exists);</p>
<p>4.1.6.organise or participate in any activity or group that is harmful, abusive, offensive, obscene, threatening, bullying, vulgar, sexually explicit, defamatory, invasive of personal privacy or encourages conduct that would violate a law or in a reasonable person&rsquo;s view be objectionable and / or inappropriate (including but not limited to where user-generated content or an online community exists);</p>
<p>4.1.7.use abusive, offensive, or defamatory screen names and / or personas;</p>
<p>4.1.8.impersonate any other person, or indicate falsely that You are an employee or a representative of Our or any of Our partners or affiliates;</p>
<p>4.1.9.promote, encourage or take part in any activity involving hacking, cracking, phishing, taking advantage of exploits or cheats and / or distribution of counterfeit software;</p>
<p>4.1.10.upload any files that contain a virus, Trojan, worm, spyware, time bombs, corrupted data or other computer programs that may damage, interfere with or disrupt the Services;</p>
<p>4.1.11.improperly use Services support or make false or spurious reports to Us; and/or</p>
<p>4.1.12.license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Services available to any third party except the Authorised Users.</p>
<p>4.2.You will obey all local laws, regulations and rules that apply to You, Your Authorised Users and Your and their use of the Services.</p>
<p>4.3.We reserve the right to access, monitor and / or record any online activity within the Services and Your Account and You give Us Your express consent to access and record Your activities within the Website or App.</p>
<p>4.4.Any breach or potential breach of any of the above rules of conduct shall be determined by Us at Our sole discretion.</p>
<p>4.5.Subject to Clause&nbsp;10, We have no liability for any loss or damage caused by Your or any third party&rsquo;s violation of the rules of conduct set out in this Clause&nbsp;4&nbsp;or Your Authorised Users&rsquo; violation of any applicable Terms of Use.</p>
<p><strong>5.USER GENERATED CONTENT</strong></p>
<p>5.1.All remarks, suggestions, ideas, graphics, or other information communicated, posted or uploaded by You via the Services (&ldquo;User Generated Content&rdquo; or &ldquo;UGC&rdquo;) shall forever be Our property. We shall have exclusive ownership of all present and future existing rights to the UGC of every kind and nature, everywhere.</p>
<p>5.2.We shall be entitled to use Your UGC for any purpose whatsoever, without compensation to You or any other person submitting the UGC.</p>
<p>5.3.You acknowledge that You are responsible for whatever material You submit, and You, not Us, have full responsibility for the UGC, including its legality, reliability, appropriateness, originality, and copyright. You agree that We shall not under any circumstances be liable for any UGC.</p>
<p>5.4.We, or third parties engaged by Us, may monitor and / or moderate UGC but We do not guarantee the accuracy, quality, or integrity of any UGC posted via the Services. By using Our Services, You acknowledge and accept that You may be exposed to material You find offensive or objectionable.</p>
<p>5.5.We reserve the right to remove and permanently delete any UGC from the Services with or without notice.</p>
<p><strong>6.AVAILABILITY AND UPDATES</strong></p>
<p>6.1.We do not warrant that Your use of the Services will be uninterrupted or error-free, or that the Services and/or the information obtained by You through the Services will be accurate and up to date, and/or meet Your requirements; and</p>
<p>6.2.We are not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of data over communications networks and facilities, including the internet, and You acknowledge that the Services may be subject to limitations, delays and other problems inherent in the use of such communications facilities.</p>
<p>6.3.We may install updates, upgrades and additional features that We deem reasonable, beneficial to You and / or reasonably necessary. These Terms shall apply to any updates, upgrades and / or additional features that are not distributed with a separate license or other agreement.</p>
<p><strong>7.TERM PERIOD</strong></p>
<p>7.1 Our agreement with You shall start on the date on which the Services commence and, unless terminated earlier in accordance with these Terms, shall continue for the period agreed between You and Us as set out in our invoice (&ldquo;Initial Term&rdquo;). Following the Initial Term, Our agreement with You and Your access to the Services shall automatically terminate unless the You renew Your subscription for a further subscription period as agreed in writing between You and Us (&ldquo;Renewal Period&rdquo;) (the Initial Term and each successive Renewal Period together being the &ldquo;Subscription Term&rdquo;).</p>
<p>7.2.These Terms apply to Your use of the Services at all times, and shall also govern Your use of the Services for the duration of the Subscription Term.</p>
<p><strong>8.CHARGES</strong></p>
<p>8.1.You will pay any charges for the Services, as shown to You when purchasing the Services or as otherwise communicated by Us to You (&ldquo;Charges&rdquo;), within 30 days of the date of the issue of an invoice in cleared funds to the bank account nominated on the face of Our invoice.</p>
<p>8.2.All Charges stated in or in relation to the Terms are non-cancellable and non-refundable and stated exclusive of VAT, unless the context requires otherwise. VAT will be payable by You to Us in addition to the principal amounts.</p>
<p>8.3.Charges may be paid by debit/credit card, cheque or bank transfer (using such payment details as are notified by Us to You from time to time).</p>
<p>8.4.If You do not pay any amount properly due to Us under or in connection with these Terms, We may:</p>
<p>8.4.1.charge You interest on the overdue amount at the rate of 4% per year above the base rate of Barclays Bank Plc from time to time (which interest will accrue daily and be compounded quarterly);</p>
<p>8.4.2.claim interest and statutory compensation from You pursuant to the Late Payment of Commercial Debts (Interest) Act 1998; and/or</p>
<p>8.4.3.suspend Yours and Authorised Users&rsquo; access to the Services until all overdue sums (including applicable interest) are fully paid.</p>
<p>8.5.We shall be entitled to vary the Charges from time to time and shall give You reasonable prior notice of any price changes.</p>
<p><strong>9.INTELLECTUAL PROPERTY RIGHTS</strong></p>
<p>9.1.You acknowledge that all intellectual property rights in the Services, all content (including but limited to all software, video, audio, music, text, characters, communications, images, sounds and all material and information) appearing within the Services existing throughout the world belong to Us, Our licensees or affiliates You do not acquire any rights in the Services other than as set out herein.</p>
<p>9.2.Any use by You of any of Our intellectual property other than as permitted under these Terms may only be undertaken with Our prior express written authorisation. Nothing contained within these Terms shall be construed as conferring any right, whether by implication, estoppel or otherwise, to use any intellectual property in the Services other than as expressly permitted in these Terms.</p>
<p><strong>10.SUSPENSION AND TERMINATION</strong></p>
<p>10.1.We may suspend, restrict or terminate Your and Your Authorised Users&rsquo; access to the Services, and consequently suspend or terminate the licence granted to You under these Terms, if:</p>
<p>10.1.1.You breach any of the restrictions or provisions in these Terms;</p>
<p>10.1.2.unable to pay its debts (within the meaning of section 123 of the Insolvency Act 1986), or becomes insolvent, or is subject to an order or a resolution for its liquidation, administration, winding-up or dissolution (otherwise than for the purposes of a solvent amalgamation or reconstruction), or has an administrative or other receiver, manager, trustee, liquidator, administrator or similar officer appointed over all or any substantial part of its assets, or enters into or proposes any composition or arrangement with its creditors generally, or is subject to any analogous event or proceeding in any applicable jurisdiction;</p>
<p>10.1.3.for reasons of a system failure, maintenance or repair or due to events beyond Our reasonable control; and / or</p>
<p>10.1.4.We decide to withdraw the Services from the market for any reason in Our sole discretion.</p>
<p>10.2.Should We wish to terminate this licence We will notify You in writing by email and/or by letter to You at the School address. Upon termination the rights and the licence granted herein will terminate and You must cease all use of the Services.</p>
<p>10.3.Termination by either party in accordance with the rights contained in this clause&nbsp;10&nbsp;shall be without prejudice to any other rights or remedies of that party accrued prior to termination.</p>
<p>11.OUR LEGAL OBLIGATIONS AND LIMITS ON LIABILITY</p>
<p>11.1.We warrant that the Services will be provided with reasonable skill and care.</p>
<p>11.2.We shall not be deemed to be in breach of the warranty at clause&nbsp;11.1&nbsp;to the extent of any non-conformance which is caused by use of the Services contrary to Our instructions, or modification or alteration of the Services by any party other than Us or Our duly authorised contractors or agents. Subject to the foregoing, if the Services do not conform with clause&nbsp;11.1, We will use reasonable commercial endeavours to correct any such non-conformance promptly. Such correction or substitution constitutes Your sole and exclusive remedy for any breach of such warranty.</p>
<p>11.3.Nothing in these Terms shall exclude or limit Our liability for fraudulent misrepresentation, death or personal injury resulting from Our negligence or the negligence of Our employees or agents, or any other liability which cannot be excluded or limited under applicable law.</p>
<p>11.4.We do not accept any liability for any damage or alteration to Your equipment including but not limited to computer equipment, handheld device or mobile telephones as a result of the installation of the App or use of the Website.</p>
<p>11.5.We do not make any representation or give any guarantee (whether express or implied) in respect of the Services, including, without limitation, any advice given to You (on a personal or general basis). You assume sole responsibility for results obtained from the use of the Services by You and Your Authorised Users, and for conclusions drawn from such use.</p>
<p>11.6.To the extent permitted by law, We hereby expressly exclude all conditions, warranties and other terms which might otherwise be implied by statute, common law or the law of equity.</p>
<p>11.7.We shall not be liable for any indirect or consequential loss or for any of the following types of loss, whether direct or indirect: any loss of business, contracts profit, anticipated savings, revenue or goodwill, or loss or corruption of data, lost or wasted management time, overhead costs and expenses, in each case howsoever caused and whether or not foreseeable.</p>
<p>11.8.Subject to the foregoing provisions, Our liability in tort, contract, negligence, non-fraudulent pre-contractual or other representations, or otherwise arising out of or in connection with these Terms shall be limited in aggregate to the sum paid by You to Us in the one year period prior to any claim issued by You.</p>
<p><strong>12.ENTIRE AGREEMENT</strong></p>
<p>12.1.The Terms, together with Our Privacy Policy and Cookie Policy, constitute the entire agreement between You and Us in relation to Your use of the Services.</p>
<p><strong>13.SEVERANCE</strong></p>
<p>13.1.If any of the Terms are or become illegal, invalid or unenforceable in any jurisdiction, that shall not affect:</p>
<p>13.1.1.the legality, validity or enforceability in that jurisdiction of any other term or condition, which shall continue to have full force and effect; or</p>
<p>13.1.2.the legality, validity or enforceability in other jurisdictions of that or any other term or condition, which shall continue to have full force and effect.</p>
<p><strong>14.RIGHTS OF THIRD PARTIES</strong></p>
<p>14.1.If You are an iOS User, Apple and Apple&rsquo;s subsidiary companies are third party beneficiaries of this agreement. This means that if You breach any of these Terms, Apple has the right to enforce it and to take action against You directly, with or without Our involvement.</p>
<p>14.2.Other than Apple and Apple&rsquo;s subsidiaries, a person who is not a party to the Terms may not enforce any of them under the Contracts (Rights of Third Parties) Act 1999.</p>
<p><strong>15.WAIVER AND ASSIGNMENT</strong></p>
<p>15.1.No waiver of any of the Terms shall be valid unless provided in writing by Us.</p>
<p>15.2.We may transfer Our rights under this agreement with You to another business without Your consent, but We will notify You of the transfer and make sure that You are not adversely affected as a result.</p>
<p>15.3.You are not allowed to transfer Your rights under these Terms to anyone without Our prior written consent.</p>
<p><strong>16.DATA PROTECTION, SECURITY AND PRIVACY</strong></p>
<p>16.1.For the purposes of these Terms, the following definitions shall apply: &ldquo;Data Protection Legislation&rdquo; means any applicable law relating to the processing, privacy and/or use of personal data, including but not limited to the UK General Data Protection Regulation, the Data Protection Act 2018, and any laws implementing or secondary to such laws, and any laws that replace, extend, re-enact, consolidate or amend any of the foregoing; and &ldquo;personal data&rdquo;, &ldquo;controller&rdquo;, &ldquo;processor&rdquo;, &ldquo;processing&rdquo; and &ldquo;data subject&rdquo; shall have the meaning ascribed to them in the Data Protection Legislation.</p>
<p>16.2.To the extent that the Services involve the processing of personal data for or on Your behalf, the parties agree that:</p>
<p>16.2.1.We are the controller of Users&rsquo; personal data provided to Us during the provision of the Services. Our registration number on the register of data controllers maintained by the Information Commissioner is ZA155224. You acknowledge and agree We may process Users&rsquo; personal data in accordance with the Data Protection Legislation and Our privacy policy (available &nbsp;<a href="http://www.masterq.co.uk/privacy-policy">here</a>&nbsp;and updated from time to time); and</p>
<p>16.2.2.We are the processor of Students&rsquo; personal data provided to Us during the provision of the Services (&ldquo;Student Personal Data&rdquo;) and the School is the controller of Student Personal Data. The parties agree that the following provisions in this clause&nbsp;16&nbsp;shall apply in respect of Our processing of Student Personal Data.</p>
<p>16.3.Each party shall at all times comply with the Data Protection Legislation in connection with the performance of these Terms, including the processing of personal data and the provision of the Services.</p>
<p>16.4.We shall only process the Student Personal Data in accordance with these Terms and the School&rsquo;s written instructions from time to time except where otherwise required by applicable law (and in such case shall inform the School of that legal requirement before processing, unless the law prevents it doing so on important grounds of public interest).</p>
<p>16.5.We shall at all times implement and maintain appropriate technical and organisational measures to protect Student Personal Data against accidental, unauthorised or unlawful destruction, loss, alteration, disclosure or access.</p>
<p>16.6.You agree that We may permit the processing of Student Personal Data by an agent or subcontractor, subject to it entering into a binding written contract containing the same obligations as under these Terms in respect of the Student Personal Data.</p>
<p>16.7.We shall:</p>
<p>16.7.1.promptly provide such information and assistance as the School may require in relation to the fulfilment of the School&rsquo;s obligations to respond to requests for exercising any data subject rights under the Data Protection Legislation;</p>
<p>16.7.2.provide such information, cooperation and assistance to the School as the School reasonably requires (taking into account the nature of processing and information available to Us) to ensure compliance with the School&rsquo;s obligations under Data Protection Legislation.</p>
<p>16.8.We shall refer all requests and communications received from data subjects or any supervisory authority, which relate or may relate to any personal data processed under these Terms, to the School promptly and shall not respond to any without the School&rsquo;s express written approval.</p>
<p>16.9.You acknowledge and agree that the Student Personal Data may be transferred or stored outside the UK or European Economic Area (EEA) (including transfers to sub-processors based outside the UK/EEA) if necessary to carry out Our obligations under these Terms provided that (a) We provide and maintain appropriate safeguards as set out in Article 46 UK GDPR to lawfully transfer the Student Personal Data to a third country; (b) the data subject has enforceable rights and effective legal remedies; and (c) We comply with reasonable instructions notified to Us in advance by You with respect to the processing of the Student Personal Data.</p>
<p>16.10.We shall maintain complete, accurate and up to date written records of all processing activities carried out on behalf of the School. Such records shall include all information necessary to demonstrate compliance with this clause&nbsp;16, the information referred to in Article 30(2) of the GDPR and such other information as the School may reasonably require from time to time.</p>
<p>16.11.We shall promptly notify the School if We suspect or becomes aware of any suspected, actual or threatened occurrence of any breach in respect of any Student Personal Data and assist the School in reporting the circumstances of any incident to a supervisory authority and/or to affected data subjects.</p>
<p>16.12.We shall promptly, on the School&rsquo;s request, either securely delete or securely return all Student Personal Data held and/or processed pursuant to these Terms to the School.</p>
<p>16.13.You warrant that You have all licences, consents and permissions necessary for Us to process the Student Personal Data in accordance with the Data Protection Legislation and as required to deliver the Services and You agree to indemnify and keep indemnified and defend at its own expense Us against all costs, claims, damages or expenses incurred by Us as a result of a breach of this warranty.</p>
<p><strong>17.HOW TO CONTACT US FOR SUPPORT OR TO REPORT COMPLAINTS, FAULTS OR ABUSE</strong></p>
<p>17.1.If You require customer support or would like to report a complaint, a fault or abusive behaviour or if You have any other questions or comments in relation to the Services then You may contact Us at&nbsp;<a href="mailto:support@masterq.co.uk">support@masterq.co.uk</a></p>
<p>17.2.iOS Users acknowledge that Apple has no obligation whatsoever to provide any support or maintenance services in relation to the App.</p>
<p><strong>18.PROHIBITED TERRITORIES AND PERSONS</strong></p>
<p>18.1.By downloading and accessing the App, You represent and warrant to Us that You are not:</p>
<p>18.1.1.located in a country that is subject to a US Government embargo, or that has been designated by the US Government as a &lsquo;terrorist supporting&rsquo; country; and</p>
<p>18.1.2.on any US Government list of prohibited or restricted parties.</p>
<p>18.2.If either of the situations in clause&nbsp;18.1&nbsp;apply to You, You are not permitted to download and use the App.</p>
<p>18.3.We do not warrant or represent that Your use of the App is lawful in any particular jurisdiction and You are responsible for ensuring that Your access and use of the App complies with all local laws and regulations. You warrant to Us that Your activities are lawful in every jurisdiction in which You access or use the App.</p>
<p><strong>19.EVENTS OUTSIDE OUR CONTROL</strong><br />We will not be liable or responsible for any failure to perform, or delay in performance of, any of Our obligations under these Terms that is caused by any act or event beyond Our reasonable control, including failure of public or private telecommunications networks.</p>
<p><strong>20.SEVERANCE</strong></p>
<p>20.1.If any provision of these Terms (or part of any provision) is or becomes illegal, invalid or unenforceable, the legality, validity and enforceability of any other provision of these Terms shall not be affected.</p>
<p>20.2.If We fail to insist that You perform any of Your obligations under these Terms, or if We do not enforce Our rights against You, or if We delay in doing so, that will not mean that We have waived Our rights against You and will not mean that You do not have to comply with those obligations. If We do waive a default by You, We will only do so in writing, and that will not mean that We will automatically waive any later default by You.</p>
<p><strong>21.NO PARTNERSHIP</strong><br />Nothing in these Terms are intended to or shall operate to create a partnership between the parties, or authorise either party to act as agent for the other, and neither party shall have the authority to act in the name or on behalf of or otherwise to bind the other in any way (including, but not limited to, the making of any representation or warranty, the assumption of any obligation or liability and the exercise of any right or power).</p>
<p><strong>22.GOVERNING LAW AND JURISDICTION</strong><br />In the event of any dispute between You and Us concerning these Terms, the laws of England and Wales will apply, to the extent permitted by local law. If You wish to take court proceedings against Us You must do so within England or Wales.</p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermsAndConditions;
