import { Outlet, Navigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import { useEffect, useState } from "react";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const PrivateRoutesWrapper = () => {
  const { user, validateCookie } = useUser();
  const [loading, setLoading] = useState<boolean>(true);
  const [authorised, setAuthorised] = useState<boolean>(false);

  useEffect(() => {
    const callValidateCookieFunction = async () => {
      try {
        const response: boolean = await validateCookie();
        setAuthorised(response);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    // only check cookie if no user obj exists in state already.
    if (user) {
      setLoading(false);
      setAuthorised(true);
    } else {
      callValidateCookieFunction();
    }
  }, [user, validateCookie]);

  if (loading) {
    return (
      <div
        style={{
          display: "grid",
          height: "100%",
        }}
      >
        <Loader
          size="md"
          content="Loading..."
          style={{
            margin: "auto",
          }}
        />
      </div>
    );
  }

  return authorised ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutesWrapper;
