import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/Dashboard/Dashboard";
import ModulesListPage from "./pages/ModulesListPage/ModulesListPage";
import StudentsListPage from "./pages/StudentsListPage/StudentsListPage";
import CreateAssignmentPage from "./pages/CreateAssignmentPage/CreateAssignmentPage";
import { Login } from "./pages/Login/Login";
import AssignmentPage from "./pages/AssignmentPage/AssignmentPage";
import AddStudentsPage from "./pages/AddStudentsPage/AddStudentsPage";
import AddTeachersPage from "./pages/AddTeachersPage/AddTeachersPage";
import AddQuestionsPage from "./pages/AddQuestionsPage/AddQuestionsPage";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SignUpPage from "./pages/SignUpPage/SignUpPage";
import HomePage from "./pages/HomePage/HomePage";
import { UserProvider } from "./context/UserContext";
import PrivateRoutesWrapper from "./components/PrivateRoutesWrapper/PrivateRoutesWrapper";
import CompleteSignUpPage from "./pages/CompleteSignUpPage/CompleteSignUpPage";
import SubmissionPage from "./pages/SubmissionPage/SubmissionPage";
import PracticePage from "./pages/PracticePage/PracticePage";
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy";
import TermsAndConditions from "./pages/Legal/TermsAndConditions";
import TermsOfUse from "./pages/Legal/TermsOfUse";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_test_51LcYZVKnX4kIIuqmPoilSwJTpuxPtO7Q0QjDGCVESgvGACXADXorZjIrXzS9FyXsrIcY8OAV2NJJI5pNcTthiWky00YbK8InCL",
);

function App() {
  return (
    <UserProvider>
      <Elements stripe={stripePromise}>
        <div className="App">
          <Routes>
            <Route element={<PrivateRoutesWrapper />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/add-students" element={<AddStudentsPage />} />
              <Route path="/add-teachers" element={<AddTeachersPage />} />
              <Route path="/add-questions" element={<AddQuestionsPage />} />
              <Route path="/subjects/:subjectId" element={<ModulesListPage />} />
              <Route path="/students" element={<StudentsListPage />} />
              <Route
                path="/create-assignment"
                element={<CreateAssignmentPage />}
              />
              <Route path="/assignment" element={<AssignmentPage />} />
              <Route path="/practice" element={<PracticePage />} />
              <Route path="/submissions/:submissionId" element={<SubmissionPage />} />
            </Route>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<SignUpPage />} />
            <Route path="/complete-sign-up" element={<CompleteSignUpPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
          </Routes>
        </div>
      </Elements>
    </UserProvider>
  );
}

export default App;
