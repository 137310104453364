import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useRef } from "react";
import axiosClient from "../../axiosClient";
import { useNavigate } from "react-router";

function FlagQuestionModal(props: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  questionId: number
}) {
  const handleClose = () => props.setShowModal(false);
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();

  return (
    <>
      <Modal show={props.showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Flag Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRef} id="my-form" onSubmit={async (e) => {
            e.preventDefault();
            const formData = Object.fromEntries(new FormData(e.target as HTMLFormElement));
            try {
              const response = await axiosClient.post(`/questions/${props.questionId}/flag`, {
                comment: formData.comment
              });

              const flaggedQuestionComment: {
                id: number,
                text: string,
                questionId: number,
                studentId: number
              } = response.data;

              alert('Thank you! Your feedback has been received, and we will review it in due course.')
            }

            catch(err) {
              alert('Sorry, error occurred saving your comment. Please try again or seek support.')
            }

            finally {
              handleClose();
            }
          }}>
            <Form.Group className="mb-3">
              <Form.Label>Enter comments</Form.Label>
              <Form.Control as="textarea"
                required
                name="comment"
                type="text"
                placeholder=""
                defaultValue=""
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" form="my-form" type="submit">
            Flag question
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FlagQuestionModal;
