import Button from "../../components/Button/Button";
import NavBar from "../../components/NavBar/NavBar";
import logo from "../../assets/logo.svg";
import "./CompleteSignUpPage.scss";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axiosClient from "../../axiosClient";

export default function CompleteSignUpPage() {

  const [searchParams] = useSearchParams()
  const [formError, setFormError] = useState<string>('')

  useEffect(() => {
    const paymentIntentId = searchParams.get('payment_intent');

    if (!paymentIntentId) {
      window.location.replace("/");
    }
  }, [])

  const setPasswordFormSubmitted = async (e: any) => {
    e.preventDefault();
    console.log(e)

    const formData = new FormData(e.target)
    const password = formData.get('password')?.toString()
    const confirmPassword = formData.get('confirmPassword')?.toString()

    if (!password) {
      setFormError('Password must not be empty!')
      return;
    }

    if (password !== confirmPassword) {
      setFormError('Passwords do not match!')
      return;
    }

    // user id won't be known until we query against payment intent in backend...
    // const response = await axiosClient.put('/users/1/set-password', {
    //   password
    // })
  }

  return (
    <>
      <NavBar />
      <div id="CompleteSignUpPage">
        <div className="site-container">
          <div className="order-summary-wrapper">
            <div className="order-summary-inner">
              <div className="big-logo-wrapper">
                <img src={logo} className="big-logo" alt="" />
              </div>

              <div className="inner-wrapper">
                <h1>Almost there 🎉</h1>
                <p>Please set your password to continue to the dashboard</p>
                <form onSubmit={setPasswordFormSubmitted}>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="password"
                    onChange={() => setFormError('')}
                  />
                  <input
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="confirm password"
                    onChange={() => setFormError('')}
                  />
                  {formError && <p style={{ color: 'red', fontSize: 'smaller', marginBottom: '5px' }}>{formError}</p>}
                  <Button text="Continue to dashboard" submit />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
