import { BsPencilFill } from "react-icons/bs";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Topbar } from "../../components/Topbar/Topbar";
import "./CreateAssignmentPage.scss";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { Class, Module } from "../../types";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../axiosClient";
import { useUser } from "../../context/UserContext";

const CreateAssignmentPage = () => {
  // const iconSize = 15;
  // const iconColour = '#323232';
  // const style = {
  //     padding: "10px",
  //     backgroundColor: "white",
  //     boxShadow: '0 1px 10px rgb(0 0 0 / 0.1)',
  //     borderRadius: '20px',
  //     marginLeft: '5px'
  // };
  const { user } = useUser()
  const teacherId = user?.teacher?.id
  const [classes, setClasses] = useState<Class[]>([]);
  const [modules, setModules] = useState<Module[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getClasses = async () => {
      const classes: Class[] = (await axiosClient.get(`/teachers/${teacherId}/classes`))
        .data;
      setClasses(classes);
      const modules: Module[] = (
        await axiosClient.get(`/subjects/${classes[0].subjectId}/modules`)
      ).data;
      setModules(modules);
      setIsLoading(false);
    };

    try {
      getClasses();
    } catch (err) {
      alert("Error loading page. Please seek support");
      console.error(err);
    }
  }, []);

  const classOptions = classes.map((c) => {
    return {
      value: c.classId,
      name: c.className,
    };
  });

  const timeAllowedOptions = [
    {
      value: 15,
      name: "15 minutes",
    },
    {
      value: 30,
      name: "30 minutes",
    },
    {
      value: 45,
      name: "45 minutes",
    },
    {
      value: 60,
      name: "1 hour",
    },
    {
      value: 75,
      name: "1 hour and 15 minutes",
    },
    {
      value: 90,
      name: "1 hour and 30 minutes",
    },
    {
      value: 105,
      name: "1 hour and 45 minutes",
    },
    {
      value: 120,
      name: "2 hours",
    },
  ];

  const onClassChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    try {
      const classId = Number(e.target.value);
      const subjectId = classes.filter((c) => c.classId === classId)[0]
        .subjectId;
      const modules: Module[] = (
        await axiosClient.get(`/subjects/${subjectId}/modules`)
      ).data;
      setModules(modules);

      // reset/clear all values
      document
        .querySelectorAll(".numberOfQuestionsSelector")
        .forEach((inputEl) => {
          (inputEl as HTMLInputElement).value = "0";
        });
    } catch (err) {
      alert("Error occured whilst loading modules. Please seek support");
      console.error(err);
    }
  };

  return (
    <div className="page-wrapper" id="CreateAssignmentPage">
      <Sidebar />
      <div className="internal-page-wrapper">
        <Topbar title="Create Assignment" />
        <main>
          {isLoading ? (
            <p>Loading....</p>
          ) : (
            <form
              action=""
              onSubmit={async (e: any) => {
                e.preventDefault();

                const numberOfQuestionsSelectors = document.querySelectorAll(
                  ".numberOfQuestionsSelector",
                );
                const moduleIdsToNumberOfQuestionsMapper: {
                  [key: number]: number;
                } = {};

                numberOfQuestionsSelectors.forEach((selector, index) => {
                  const correspondingModule = modules[index];
                  const numberOfQuestionsToIncludeFromThisModule = Number(
                    (selector as HTMLInputElement).value,
                  );
                  moduleIdsToNumberOfQuestionsMapper[correspondingModule.id] =
                    numberOfQuestionsToIncludeFromThisModule;
                });

                const payload = {
                  name: e.target.name.value,
                  dueDate: e.target.examDueDate.value,
                  classId: e.target.class.value,
                  timeAllowed: e.target.timeAllowed.value,
                  passMark: e.target.passMark.value,
                  modules: {
                    ...moduleIdsToNumberOfQuestionsMapper,
                  },
                };

                try {
                  await axiosClient.post(`/assignments/`, payload);
                  alert("Assignment created successfully.");
                  navigate("/dashboard");
                } catch (err) {
                  alert(
                    "Error occurred whilst creating assignment! Please seek support.",
                  );
                  console.error(err);
                }
              }}
            >
              <div className="white-box">
                <div className="exam-details">
                  <Input
                    label="Name"
                    name="name"
                    type="text"
                    placeholder="Assignment name"
                  />
                  <Input label="Exam due date" name="examDueDate" type="date" />
                  <Input
                    label="Class"
                    type="select"
                    name="class"
                    options={classOptions}
                    onChange={onClassChange}
                  />
                  <Input
                    label="Time allowed"
                    type="select"
                    name="timeAllowed"
                    options={timeAllowedOptions}
                  />
                  <Input
                    label="Pass mark (%)"
                    name="passMark"
                    type="number"
                    placeholder="Pass mark (%)"
                  />
                </div>

                <div className="topicQuestionAllocation">
                  <div className="titles">
                    <div>Modules</div>
                    <div>Questions available</div>
                    <div>Questions to include</div>
                  </div>
                  <div className="allocations">
                    {modules.map((m) => {
                      return (
                        <div
                          className="allocation-wrapper"
                          onClick={(e) => {
                            // Use `currentTarget` instead of `target`. Because if a user clicks on the <input/> inside the <div/>, then
                            // `.querySelector('.numberOfQuestionsSelector')` will return `null`, and `.focus()` will fail. `currentTarget`
                            // returns the <div/> where the `onClick()` listener exists, regardless of where the click was done inside it.
                            // See https://stackoverflow.com/a/10086501/13471035
                            const clickedDiv =
                              e.currentTarget as HTMLDivElement;
                            const inputSelector = clickedDiv.querySelector(
                              ".numberOfQuestionsSelector",
                            ) as HTMLInputElement;
                            inputSelector.focus();
                          }}
                        >
                          <div className="topicName">{m.name}</div>
                          <div className="numberOfQuestions">
                            {m.numberOfQuestions}
                          </div>
                          <div className="numberOfQuestionsSelectorWrapper">
                            <input
                              type="number"
                              name="numberOfQuestionsSelector"
                              min={0}
                              max={m.numberOfQuestions}
                              step={1}
                              className="numberOfQuestionsSelector"
                              defaultValue={0}
                            />
                            <BsPencilFill size={12} color="#7f7f7f" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="buttons">
                  <Button text="Cancel" type="cancel" />
                  <Button text="Create" submit />
                </div>
              </div>
            </form>
          )}
        </main>
      </div>
    </div>
  );
};

export default CreateAssignmentPage;
