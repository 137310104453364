import "./CourseCard.scss";
import { Link } from "react-router-dom";
import { Class } from "../../types";
import bioImg from "../../assets/course-cards/biology.jpg";
import mathsImg from "../../assets/course-cards/maths.jpg";
import chemistryImg from "../../assets/course-cards/chemistry.jpg";
import physicsImg from "../../assets/course-cards/physics.jpg";
import ProgressBar from "@ramonak/react-progress-bar";

// `_class` used because `class` is a protected word
const CourseCard = (_class: Class) => {
  let bgImage;
  switch (_class.subjectName) {
    case "Maths":
    case "Mathematics":
      bgImage = mathsImg;
      break;
    case "Biology":
      bgImage = bioImg;
      break;
    case "Chemistry":
      bgImage = chemistryImg;
      break;
    case "Physics":
      bgImage = physicsImg;
      break;
    default:
      bgImage = undefined;
      break;
  }

  const completedPercentage = parseFloat(((_class.numberOfQuestionsCompleted / _class.numberOfQuestions) * 100).toFixed(2))

  return (
    <Link
      className="course"
      to={`/subjects/${_class.subjectId}`}
    >
      <div
        className="course-bg-image"
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
      ></div>
      <div className="course-title-wrapper">
        <span>{_class.className}</span>
        <span id="module-count">
          {_class.modulesCount} module{_class.modulesCount === 1 ? "" : "s"}
        </span>
      </div>
      {/* <div className="course-metadata-wrapper">
        <span>Subject: {_class.subjectName}</span>
        <span>{_class.numberOfQuestions} questions</span>
      </div> */}

      <div className="progress-bar-wrapper">
        <div className="progress-title-wrapper">
          <span>Progress</span>
          <span>{_class.numberOfQuestionsCompleted} / {_class.numberOfQuestions} questions ({completedPercentage}%)</span>
        </div>
        <ProgressBar
          completed={completedPercentage}
          bgColor="#316AFF"
          height="6px"
          isLabelVisible={false}
        />
      </div>
    </Link>
  );
};

export default CourseCard;
