import { BsPencilFill } from "react-icons/bs"
import { Assignment, Question } from "../../types";
import CountdownTimer from "../CountdownTimer/CountdownTimer";
import { Button } from "react-bootstrap";

const AssignmentPageSidebar = (props: {
    setCurrentQuestionIndex: (index: number) => void,
    selectedAnswers: {
        [key: number]: number;
    },
    questions: Question[],
    assignment: Assignment | undefined,
    submit: () => void,
}) => {
    return (
        <>
            <div className="questions-progress-tracker">
                {props.assignment?.duration && (
                    <div className="sticky-top-part">
                        <CountdownTimer submit={props.submit} targetDateInMilliseconds={Number(localStorage.getItem(`master_q_assignment_${props.assignment.id}_start_time`)) + (props.assignment.duration * 60 * 1000)} />
                    </div>
                )}
                <div className="past-questions-tracker">
                    <ul>
                        {props.questions.map((question, index) => {

                            const questionAnswered = !!props.selectedAnswers[question.id];

                            return (
                                <li style={{  }}>
                                    <Button size="sm" variant={questionAnswered ? 'dark' : 'light'} onClick={() => {
                                        props.setCurrentQuestionIndex(index)
                                    }} style={{  }}><BsPencilFill size={12} style={{ marginRight: '5px' }} />Q{index + 1}</Button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>

        </>
    )
}

export default AssignmentPageSidebar;