import Sidebar from "../../components/Sidebar/Sidebar";
import { Topbar } from "../../components/Topbar/Topbar";
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from "react-router-dom";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import { useEffect, useRef, useState } from "react";
// NOTE: uses scss from AssignmentPage component
import "../AssignmentPage/AssignmentPage.scss";
import axiosClient from "../../axiosClient";
import { ZoomImage } from "../../components/ZoomImage/ZoomImage";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { ImCross } from "react-icons/im";
import { Loader } from "rsuite";
import { AssignmentPageFooter } from "../AssignmentPage/AssignmentPageFooter";
import PracticePageSidebar from "../../components/PracticePageSidebar/PracticePageSidebar";
import { Question } from "../../types";

const SubmissionPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // const [assignment, setAssignment] = useState<Assignment>();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  // selectedAnswers maps each questionId to the answerId chosen
  const [selectedAnswers, setSelectedAnswers] = useState<{
    [key: number]: number;
  }>({});
  const [assignmentName, setAssignmentName] = useState<string>("")
  const navigate = useNavigate();

  let { submissionId } = useParams();

  useEffect(() => {
    const getQuestions = async () => {
      try {
        const submissionResp: {
          id: number;
          submittedDate: string;
          assignmentId: number;
          assignment: {
            name: string
          };
          studentId: number;
          score: number;
          questions: Question[];
        } = (await axiosClient.get("/submissions/" + submissionId))
            .data;
  
        const submissionAnswers = {};
        submissionResp.questions.forEach((question) => {
          let submissionAnswer;
  
          submissionAnswer = question.answerOptions.filter(
            (answerOption) => answerOption.selected,
          );
  
          if (submissionAnswer.length) {
            (submissionAnswers as any)[question.id] =
              submissionAnswer[0].id;
          }
        });
        setAssignmentName(submissionResp.assignment.name)
        setQuestions(submissionResp.questions);
        setSelectedAnswers(submissionAnswers);
        setIsLoading(false);
      }

      catch (error) {
        navigate('/dashboard')
      }
    }

    getQuestions();
  }, [])
  
  if (isLoading) {
    return (
      <div className="page-wrapper" id="SubmissionPage">
        <Sidebar />
        <Loader
          size="md"
          content="Loading..."
          style={{
            margin: "auto",
          }}
        />
      </div>
    );
  }

  const ABCDE = "ABCDE";
  const currentQuestion = questions[currentQuestionIndex];

  const answeredCorrectly =
    currentQuestion.answerOptions.filter(
      (answerOption) => answerOption.isCorrect && answerOption.selected,
    ).length === 1;
  const noAnswerSubmittedForThisQuestion =
    currentQuestion.answerOptions.filter(
      (answerOption) => answerOption.selected,
    ).length === 0;

  const delimeters = [
    {
      // NOTE: `display` is by default `true` for the '$$' delimeter option
      // out the box from the react-latex-next package. However, I've set it
      // to `false` to ensure it doesn't render the equation in the centre
      // of the parent. Instead, `false` ensures the equation is rendered
      // in-line with the text. I should also point out that I could have just
      // used the single '$' delimeter option, where all questions with equations
      // have the equation wrapped with a single '$' either side, but I thought
      // this was risky if by accident a question has a $ sign in the question
      // text itself. In such a case, we might not realise, and the question
      // would render incorrectly. Thus, I have opted for the '$$' approach,
      // despite it meaning that we have to manually define the delimeters in
      // our implementation (which wouldn't have been the case otherwise).
      // See: https://www.npmjs.com/package/react-latex-next
      display: false,
      left: "$$",
      right: "$$",
    },
  ];

  const explanationLines = currentQuestion.explanation?.text
    ?.split("\\n")
    .map((line) => (
      <p className="explanation-line">
        <Latex delimiters={delimeters}>{line}</Latex>
      </p>
    ));


  const handleQuestionChange = (e: { selected: number }) => {
    const newQuestionIndex = e.selected;
    setCurrentQuestionIndex(newQuestionIndex);
  };
  

  return (
    <div className="page-wrapper" id="SubmissionPage">
      <Sidebar />
      <div className="internal-page-wrapper">
        <Topbar title={assignmentName} />
        <main>
          <div className="inner-main-wrapper row">
            <div className="inner-max-width-wrapper col-10">
              <div className="top-area">
                <h4>
                  Question{" "}
                  {currentQuestionIndex + 1}{" "}
                  of{" "}
                  {questions.length}
                </h4>
                {/* <Button text="Skip question" type="inverse" /> */}
              </div>
              { noAnswerSubmittedForThisQuestion ? (
                <p className="question-was-not-answered">
                  NOTE: You completed the assignment without answering this
                  question.
                </p>
              ) : undefined}

              <h5 className="questionTitle">
                <Latex delimiters={delimeters}>{currentQuestion.text}</Latex>
              </h5>

              { currentQuestion.imageUrl && (
                <div style={{
                  marginBottom: '50px'
                }}>
                  <ZoomImage src={currentQuestion.imageUrl} width={300}></ZoomImage>
                </div>
              ) }

              <div className="answer-options-wrapper">
                {currentQuestion?.answerOptions.map((answerOption, index) => {
                  const option = ABCDE[index];
                  const classes = ["answer-option"];
                  classes.push("locked");

                  if (answerOption.selected) {
                    // gives it the blue outline
                    classes.push('selected-answer-option');
                  }
                  return (
                    <div
                      className={classes.join(" ")}
                      // onClick={handleAnswerSelected}
                      id={answerOption.id.toString()}
                    >
                      <div>
                        <div className="top-part">
                          <label htmlFor={option}>{option}</label>


                          { answerOption.isCorrect && <IoIosCheckmarkCircle color="green" size={25} />}
                          { !answerOption.isCorrect && <ImCross color="white" size={20} style={{
                            backgroundColor: 'red',
                            borderRadius: '10px',
                            padding: '5px'
                          }} />}
                          

                          <input
                            type="checkbox"
                            disabled
                            name={option}
                            id={option}
                            style={{
                              display: 'none'
                            }}
                          />
                        </div>
                        <hr />
                        <div className="bottom-part">
                          <p>
                            <Latex delimiters={delimeters}>
                              {answerOption.text}
                            </Latex>
                          </p>
                          { answerOption.imageUrl && <ZoomImage src={answerOption.imageUrl} width={200}></ZoomImage> }
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                className={
                  answeredCorrectly
                    ? "explanation explanation-correct"
                    : "explanation explanation-incorrect"
                }
              >
                <div className="explanation-title">
                  {answeredCorrectly ? <span>Correct! <IoIosCheckmarkCircle color="green" size={25} style={{
                    position: 'relative',
                    bottom: '2px'
                  }} /></span>: <span>Incorrect! <ImCross color="white" size={20} style={{
                          backgroundColor: 'red',
                          borderRadius: '10px',
                          padding: '5px',
                          position: 'relative',
                          bottom: '2px'
                        }} /></span>}
                </div>
                {explanationLines}
                <div style={{
                  display: 'flex',
                  gap: 20,
                  marginTop: 20
                }}>
                  { currentQuestion.explanation?.images.map(image => {
                    return <ZoomImage src={image.url} width={150}></ZoomImage>
                  })}
                </div>
              </div>


              <div id="assignment-page-footer-outer-wrapper">
                <div id="assignment-page-footer-inner-wrapper">
                  <>
                    <AssignmentPageFooter
                      currentQuestionIndex={currentQuestionIndex}
                      handleQuestionChange={handleQuestionChange}
                      totalQuestions={questions.length} />
                  </>
                </div>
              </div>
            </div>
            <div className="col-2">
              <PracticePageSidebar
                submittedPracticeQuestions={questions}
                onSubmittedQuestionClicked={(index: number) => {
                    setCurrentQuestionIndex(index)
                }}
              />
          </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SubmissionPage;
