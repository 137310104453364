import Button from "../../components/Button/Button";

export const PracticePageFooter = (props: {
    submitPracticeModeSingleQuestion: () => Promise<void>;
    submitButtonDisabled: boolean;
    handleQuestionChangePracticeMode: () => Promise<void>;
    nextButtonDisabled: boolean;
}) => {
    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <div>
                    <Button
                        text="Submit Answer"
                        onClick={props.submitPracticeModeSingleQuestion}
                        disabled={props.submitButtonDisabled}
                        type="navy"
                    ></Button>
                </div>

                <div
                    style={{
                        marginLeft: "10px",
                    }}
                >
                    <Button
                        text="Next"
                        onClick={props.handleQuestionChangePracticeMode}
                        disabled={props.nextButtonDisabled}
                    ></Button>
                </div>
            </div>
        </>
    )
}
