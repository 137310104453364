import { useState } from "react";
import { HiMiniFlag } from "react-icons/hi2"
import FlagQuestionModal from "../../components/Modal/FlagQuestionModal";
import { Button as BootstrapButton } from "react-bootstrap";
import { IoIosCheckmarkCircle } from "react-icons/io";
import Button from "../../components/Button/Button";

export const AssignmentPageTitle = (props: {
    questionNumber: number,
    totalQuestions: number,
    questionId: number,
    isAssignmentMode: boolean,
    submitAssignment?: () => void,
}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const handleShowModal = () => setShowModal(true);
    return (
        <>
            <h4>
                Question{" "}
                {props.questionNumber}{" "}
                of{" "}
                {props.totalQuestions}
            </h4>

            <div style={{
                display: 'flex',
                gap: '15px'
            }}>


                <button
                    onClick={handleShowModal}
                    style={{
                        display: 'flex',
                        borderRadius: '3px',
                        height: 'fit-content'
                    }}
                >
                    <div style={{
                        padding: '2px'
                    }}>
                        <HiMiniFlag color="red" style={{
                            margin: 'auto'
                        }} />
                    </div>
                </button>

                {props.isAssignmentMode && (
                    <BootstrapButton variant="dark" onClick={props.submitAssignment} style={{
                        fontSize: '0.9rem'
                    }}>Submit Exam <IoIosCheckmarkCircle />
                    </BootstrapButton>
                )}


            </div>



            {/* <Button text="Skip question" type="inverse" /> */}
            <FlagQuestionModal setShowModal={setShowModal} showModal={showModal} questionId={props.questionId}></FlagQuestionModal>

        </>
    )
}
